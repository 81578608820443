'use strict';

angular.module('dfs.shop-promo', []).directive('dfsShopPromo', function () {
    return {
        name: 'dfsShopPromo',
        templateUrl: 'dfsShopPromo.html',
        replace: true,
        scope: {
            'show': '@'
        },
        controller: 'dfsShopPromoCtrl'
    };
});

require('./dfsShopPromoConstant');
require('./dfsShopPromoService');
require('./dfsShopPromoCtrl');
'use strict';

/* @ngInject */
angular.module('bm.squad.fixtures').controller('SquadFixturesCtrl', function (MatchDayService, bulimaConfig) {
    var self = this;

    MatchDayService.getNextMatches().then(function(matches) {
        self.matches = matches;
    });

    self.isInternational = bulimaConfig.INTERNATIONAL;
});
'use strict';

angular.module('bm.squad.statistics', []).directive('bmSquadStatisticsTabs', function () {
    return {
        name: 'bmSquadStatisticsTabs',
        templateUrl: 'bmSquadStatisticsTabs.html',
        controller: 'bmSquadStatisticsTabsCtrl',
        replace: true,
        scope: {
            playerId : '=playerId',
            leagueId: '=leagueId'
        }
    };
});

require('./bmSquadStatisticsTabsCtrl');


'use strict';

/* @ngInject */
angular.module('bm.create.news').controller('CreateNewsCtrl', function($scope, NewsService, NewsModel, ClubLeagueActivitiesService, ClubService, Notifier) {
    var self = this;

    this.news = new NewsModel();

    this.postNews = function(form, news) {
        form.$showErrors = false;
        if (form.$invalid) {
            form.$showErrors = true;
            return;
        }

        news.post()
            .then(function(newNews) {
                NewsService.addMyNews(newNews);
                self.news = new NewsModel();
                Notifier.success('CREATE_NEWS.NOTIFICATION_SUCCESS_CREATE');
                $scope.News.tabOpen = 'previewNews';
            });
    };

    this.recepientDrop = {
        open: false
    };

    function openDropdown() {
        var myClub = ClubService.myClub;
        ClubLeagueActivitiesService.getTeamsList(myClub.fantasyLeague.id)
            .then(function(clubs) {
                self.clubs = clubs.filter(function(club) {
                    return club.id !== myClub.id;
                });
                self.recepientDrop.open = true;
            });
    }

    function closeDropdown() {
        self.recepientDrop.open = false;
    }

    this.toggleDropdown = function(event, type) {
        if (event.target.tagName === 'SPAN') {
            return;
        }
        if (type === 'official') {
            this.news.removeReceiver();
            this.selected = {};
        }
        if (this.recepientDrop.open) {
            if (!Object.keys(this.selected).length) {
                this.removeReceiver();
            } else {
                setReceivers(this.selected);
            }
        } else if (type === 'private') {
            openDropdown();
        }
    };

    this.selected = {};

    this.toggleReceiver = function(club) {
        if (this.selected[club.id]) {
            this.selected = {};
            return;
        }
        this.selected = {};
        this.selected[club.id] = club;
    };

    function setReceivers(selected) {
        self.news.setReceivers(selected);
        closeDropdown();
    }

    this.removeReceiver = function(club) {
        if (this.news.removeReceiver(club) === 0) {
            this.news.type = 'official';
            closeDropdown();
        }
    };

    this.closeReceivers = function() {
        var receivers = this.news.getReceivers() || {};
        if (!Object.keys(receivers).length) {
            this.news.type = 'official';
        }
        this.selected = receivers;
        closeDropdown();
    };

});

'use strict';
angular.module('bm.googletag').controller('GoogletagCtrl', GoogletagCtrl);

/* @ngInject */
function GoogletagCtrl($rootScope, $scope, $state, UserService, bulimaConfig) {
    var variantConfig = {
        'BB': {
            browser: '/8317785/AU_BS_WW_BB_Browser',
            screens: [[970, 250], [970, 90], [728, 90]]
        },
        'HP': {
            browser: '/8317785/AU_BS_WW_HP_Browser',
            screens: [[300, 600], [160, 600], [120, 600]]
        },
        'MPU': {
            browser: '/8317785/AU_BS_WW_MPU_Browser',
            screens: [[300, 250], [250, 250], [200, 200]]
        }
    };

    var manager = UserService.getManagerInfo();
    var date = new Date();
    var UTCtimestamp = date.getTime() + date.getTimezoneOffset() * 60000;

    $scope.slot = variantConfig[$scope.variant];
    $scope.isPremiumUser = manager.user.premiumStatusActiveTimestamp > UTCtimestamp;
    $scope.isInternational = bulimaConfig.INTERNATIONAL;
}

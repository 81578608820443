'use strict';

angular.module('bm.squad-positions', []).directive('bmSquadPositions', function () {
	return {
	    name: 'bmSquadPositions',
		templateUrl: 'squad-positions-component.html',
		scope: {
		    'formation': '=bmSquadPositions'
		},
		link: function (scope) {
		    scope.squad = scope.formation !== null && scope.formation.length > 1 ? scope.formation.split('-') : [1, 1, 1, 1];
		}
	};
});
'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsHref', function ($state, $location, $anchorScroll) {
    return {
        name: 'dfsHref',
        restrict: 'A',
        scope: {
            'link': '@dfsHref',
            'anchor': '@href'
        },
        link: function (scope, element) {
            element.on('click', function (e) {
                e.preventDefault();
                if (scope.link) {
                    $state.go(scope.link).then(function () {
                        scope.scrollTo(scope.anchor);
                    });
                } else {
                    scope.scrollTo(scope.anchor);
                }
            });

            scope.scrollTo = function (el) {
                $location.hash(el);
                $anchorScroll.yOffset = 80;
                $anchorScroll();
            };
        }
    };
});
'use strict';

angular.module('bm.popmenu', []).directive('bmPopmenu', function () {
	return {
	    name: 'bmPopmenu',
        transclude: true,
        templateUrl: 'popmenu-component.html',
        scope: true,
		replace: true,
		link: function (scope) {
		    var $document = angular.element(document);

		    scope.showMenu = false;

		    scope.toggleMenu = function ($event) {
		        scope.showMenu = !scope.showMenu;
		        $event.stopPropagation();
		        if (scope.showMenu) {
		            $document.on('click.popmenu', function () {
		                scope.showMenu = false;
		                $document.off('click.popmenu');
		            });
		        }
		    };

		    scope.$on('$destroy', function () {
		        $document.off('click.popmenu');
		    });
		}
	};
});

'use strict';

angular.module('dfs.footer', []).directive('dfsFooter', function () {
    return {
        name: 'dfsFooter',
        templateUrl: 'dfsFooter.html',
        controller: 'dfsFooterCtrl',
        replace: true,
        scope: {
            'fullWidth': '@',
            'responsive': '@'
        }
    };
});

require('./dfsFooterCtrl');

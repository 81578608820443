'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsProfileBox', function () {
    return {
        name: 'dfsProfileBox',
        templateUrl: 'dfsProfileBox.html',
        replace: true,
        scope: {
            profileImg: '@',
            profileName: '@',
            profileTitle: '@',
            profileSize: '@',
            profileClub: '@',
            profileClubImg: '@',
            profileClubHide: '=',
            profileClubClick: '&',
            profileClick: '&'
        }
    };
});
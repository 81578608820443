'use strict';

/* @ngInject */
angular.module('bm.squad.lineup.captain').factory('SquadLineupCaptainService',function (ReserveDispatcher) {

    var captain = null;

    /* Set a player as captain */
    function set(player) {
        if (player) {
            if (player.slot < 12) {
                if (captain) {
                    captain.setCaptain(false);
                }
                player.setCaptain(true);
            }
        }
        captain = player;
        ReserveDispatcher.replaceCaptain(captain);
    }

    /* Get captain */
    function get() {
        return captain;
    }

    return {
        get: get,
        set: set
    };
});

'use strict';

require('../bm.bug-report/BugReportModal');

/* @ngInject */
function FooterCtrl($scope, BugReportModal, bulimaConfig) {

    $scope.fbUrl = bulimaConfig.INTERNATIONAL ? 'https://www.facebook.com/GermanBundesligaStars ' : 'https://www.facebook.com/SPORT1BundesligaManager';
    $scope.openBugReport = function() {
        BugReportModal.open();
    };
}

angular.module('bm.footer').controller('FooterCtrl', FooterCtrl);

'use strict';

require('./BugReportService');

angular.module('bulima.shared').controller('BugReportCtrl', BugReportCtrl);

/* @ngInject */
function BugReportCtrl($scope, $modalInstance, $modal, BugReportService, UserService) {
    var manager = UserService.getManagerInfo();
    if (manager) {
        $scope.report = {
            email: manager.user.email,
            id: manager.user.id
        };

        $scope.user = {
            username: manager.username
        };
    }

    $scope.errorTypes = [
        { id: 'Account', value: 'DFS_BUG_REPORT.SELECT_ACCOUNT' },
        { id: 'Challenges', value: 'DFS_BUG_REPORT.SELECT_CHALLENGES' },
        { id: 'Registration', value: 'DFS_BUG_REPORT.SELECT_REGISTRATION' },
        { id: 'Results', value: 'DFS_BUG_REPORT.SELECT_RESULTS' },
        { id: 'Shop', value: 'DFS_BUG_REPORT.SELECT_SHOP' },
        { id: 'Others', value: 'DFS_BUG_REPORT.SELECT_OTHER' }
    ];

    $scope.selectedErrorType = null;

    $scope.selectErrorType = function (errorType) {
        $scope.report.errorType = errorType.id;
    };

    $scope.datePicker = {
        opened: false,
        format: 'dd/MM/yy',
        time: new Date(),
        maxDate: new Date(),
        minDate: new Date(2015, 7, 1),
        open: function($event) {
            $event.preventDefault();
            this.opened = true;
        },
        dateOptions: {
            formatYear: 'yy',
            startingDay: 1
        }
    };

    $scope.sendReport = function(report, form) {
        if (form.$invalid) {
            form.$showErrors = true;
            return;
        }
        $scope.report.description = $scope.report.description || '';
        $scope.report.reportDate = $scope.datePicker.time.getTime();
        form.$showErrors = false;
        BugReportService.sendReport(report)
            .then(function() {
                $modalInstance.close();
                $modal.open({
                    templateUrl: 'bug-report-success.html',
                    size: 'bugReport'
                });
            });
    };
}

'use strict';

angular.module('dfs.header', ['dfs.common']).directive('dfsHeader', function () {
    return {
        name: 'dfsHeader',
        templateUrl: 'dfsHeader.html',
        controller: 'dfsHeaderCtrl',
        replace: true,
        scope: {
            'activeHeader': '@',
            'headerLink': '@'
        }
    };
});

require('./dfsHeaderCtrl');

'use strict';

angular.module('bm.slider', []).service('SliderService', SliderService);

/* @ngInject */
function SliderService($http, $q, bulimaConfig) {

    var deferedContent;

    function getSliderContent(isStatic) {
        deferedContent = $q.defer();
        var data = [];
        if (!isStatic) {
            $http.post(bulimaConfig.BASE_URL + '/commonservice/getSliderContents')
                .then(function(response) {
                    data = response.data;
                    for (var i = 0; i < data.length; i++) {
                        data[i].externalLink = data[i].redirectUrl.indexOf('http') !== -1;
                    }
                    deferedContent.resolve(data);
                });
        } else {
            // If we need some predefined slider image sometime.
            // data = [];
            // data[0] = {
            //     id: 1,
            //     contentUrl: '../assets/images/theme/christmas_cup_slider.png',
            //     redirectUrl: '',
            //     text: '',
            //     externalLink: false
            // };
            // deferedContent.resolve(data);
        }
        return deferedContent.promise;
    }

    function getSliderContentDfs(isStatic) {
        deferedContent = $q.defer();
        var data = [];
        if (!isStatic) {
            $http.post(bulimaConfig.BASE_URL + '/commonservice/getSliderContentsDFS')
                .then(function (response) {
                    data = response.data;
                    for (var i = 0; i < data.length; i++) {
                        data[i].externalLink = data[i].redirectUrl.indexOf('http') !== -1;
                    }
                    deferedContent.resolve(data);
                });
        } else {
            // If we need some predefined slider image sometime.
            // data = [];
            // data[0] = {
            //     id: 1,
            //     contentUrl: '../assets/images/theme/christmas_cup_slider.png',
            //     redirectUrl: '',
            //     text: '',
            //     externalLink: false
            // };
            // deferedContent.resolve(data);
        }
        return deferedContent.promise;
    }

    this.getSliderContent = getSliderContent;
    this.getSliderContentDfs = getSliderContentDfs;
}

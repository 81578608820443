'use strict';

/* @ngInject */
angular.module('bm.scrollWrapper').directive('bmScrollWrapper', function($rootScope, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, element) {

            function stateChangeHandler() {
                $timeout(function() {
                    element.animate({
                        scrollTop: 0
                    }, 300);
                }, 500);
            }

            $rootScope.$on('$stateChangeSuccess', stateChangeHandler);
            
        }
    };
});

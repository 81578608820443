'use strict';

angular.module('bm.team-calculation-player', []).directive('bmTeamCalculationPlayer', function () {
	return {
	    name: 'bmTeamCalculationPlayer',
	    templateUrl: 'team-calculation-player-component.html',
		replace: true,
		scope: {
		    'player': '=',
            'isChallenge': '='
		},
		link: function (scope) {
		    scope.imageUrl = scope.player.imageUrl || 'assets/images/player/noplayer.svg';
		    scope.showDetails = false;
		    scope.toggleDetails = function () {
		        if ((scope.player.points && scope.player.points !== '-') || scope.hasChallengeDetails) {
		            scope.showDetails = !scope.showDetails;
		        }
		    };

		    if (scope.isChallenge && scope.player.stats && scope.player.player) {
		        scope.challengePlayer = {
		            points: scope.player.stats[scope.player.stats.length - 1].value,
		            imageUrl: scope.player.player.photoUrl2 || 'assets/images/player/noplayer.svg',
		            name: scope.player.player.trikotName,
		            club: scope.player.player.club ? scope.player.player.club.mediumName : null,
                    stats: scope.player.stats
		        };
		        scope.hasChallengeDetails = scope.player.stats.length > 1 && scope.challengePlayer.points !== '-';
		    }
		}
	};
});

require('./bm.team-calculation-player.points/bm.team-calculation-player.points');
require('./bm.team-calculation-player.details/bm.team-calculation-player.details');
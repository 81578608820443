'use strict';

angular.module('localytics')
    .provider('ll', function() {

        var llGlobal = 'll';

        this.init = function(appKey) {
            init(appKey);
        };

        function init(appKey) {
            window[llGlobal]('init', appKey, {});
        }

        (function(w, d, llGlobal) {
            var js,
                id = 'localytics-jssdk',
                ref = d.getElementsByTagName('script')[0];

            if (d.getElementById(id)) {
                return;
            }

            js = d.createElement('script');
            js.id = id;
            js.async = true;

            w.LocalyticsGlobal = llGlobal;
            w[llGlobal] = function() {
                (w[llGlobal].q = w[llGlobal].q || []).push(arguments);
            };
            w[llGlobal].t = Date.now();

            js.src = '//web.localytics.com/v3/localytics.js';
            ref.parentNode.insertBefore(js, ref);
        })(window, document, llGlobal);

        this.$get = [function() {
            return window.ll;
        }];
    });

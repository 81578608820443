'use strict';

angular.module('bm.rankchange', []).directive('bmRankchange', function () {
	return {
		name: 'bmRankchange',
		templateUrl: 'rankchange-component.html',
		replace: true,
		scope: {
            'diff': '=bmRankchange'
		}
	};
});

'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsCoin', function () {
    return {
        name: 'dfsCoin',
        templateUrl: 'dfsCoin.html',
        replace: true,
        scope: {
            coinValue: '=',
            coinType: '@',
            coinCost: '@'
        }
    };
});
'use strict';

angular.module('bm.stats-item').directive('bmStatsItemDetails', function ($filter) {
	return {
	    name: 'bmStatsItemDetails',
	    templateUrl: 'stats-item-details-component.html',
		replace: true,
		scope: {
		    'item': '=',
            'detailsProp': '='
		},
		link: function (scope) {
		    scope.getProp = function (item, prop, filter, filterProp) {
		        var propArr = prop.split('.'),
                    res = angular.copy(item);

		        propArr.forEach(function (p) {
		            res = res[p];
		        });

		        if (res) {
		            if (filter) {
		                if (filterProp) {
		                    return $filter(filter)(res, filterProp);
		                } else {
		                    return $filter(filter)(res);
		                }
		            } else {
		                return res;
		            }
		        } else {
		            return '-';
		        }
		    };
		    scope.getTranslateProp = function (item, prop, translate, translateAdd) {
		        if (translate && translateAdd) {
		            return translateAdd + scope.getProp(item, prop);
		        } else {
		            return scope.getProp(item, prop);
		        }
		    };
		    scope.getLabel = function (item, stat) {
		        if (stat.labelFilter) {
		            return $filter(stat.labelFilter)(scope.getProp(item, stat.label), stat.labelFilterProp);
		        } else {
		            return stat.label;
		        }
		    };

		}
	};
});

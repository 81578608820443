'use strict';

/* @ngInject */
angular.module('bm.footer').directive('bmFooterPosition', function($timeout) {
    return {
        link: function(scope, element) {

            var doc = angular.element(document);

            function checkHeight() {
                var gameWrapper = angular.element(document.getElementsByClassName('wrapper--game')[0]);
                // var childWrapper = angular.element(gameWrapper.children()[0]);
                // if (childWrapper.height() + 55 < gameWrapper.height()) {
                if (gameWrapper.height() + 206 < doc.height()) {
                    element.css({
                        'bottom': '0px'
                    });
                } else {
                    element.css({
                        'bottom': ''
                    });
                }
            }

            var timer;

            function checkHeightRepeat() {
                return $timeout(function() {
                    checkHeight();
                    timer = checkHeightRepeat();
                }, 100);
            }

            timer = checkHeightRepeat();

            scope.$on('$destroy', function() {
                $timeout.cancel(timer);
            });
        }
    };
});

'use strict';

require('./NewsModel');

angular.module('bm.news').service('NewsService', NewsService);

/* @ngInject */
function NewsService($q, $rootScope, NewsModel) {

    var self = this;

    this.setNews = setNews;
    this.addMyNews = addMyNews;
    this.setMoreNews = setMoreNews;
    this.news = [];

    // var newsTypes = ['global', 'league', 'club', 'private'];
    var news;

    function setNews(newNews) {
        news = {
            global: [],
            private: [],
            league: [],
            club: []
        };
        if (newNews.length) {
            if (newNews && newNews[0].newsType === 'lineup') {
                var lineup = newNews[0].newsMessage.split('<br/>');
                if (lineup.length) {
                    news.lineup = {
                        formation: lineup[0],
                        goal: lineup[1],
                        defense: lineup[2],
                        middle: lineup[3],
                        attack: lineup[4]
                    };
                }
                newNews = newNews.splice(1);
            }
            if (newNews.length) {
                for (var i = 0; i < newNews.length; i++) {
                    var singleNews = NewsModel.new(newNews[i]);
                    if (!news[singleNews.category]) {
                        news[singleNews.category] = [];
                    }
                    news[singleNews.category].push(singleNews);
                    news.global.push(singleNews);
                }
            }
            self.news = news;
        }
    }

    function addMyNews(newNews) {
        if (newNews.category === 'league') {
            news.league.unshift(newNews);
        } else if (newNews.category === 'private') {
            news.private.unshift(newNews);
        }
        news.global.unshift(newNews);
    }

    function setMoreNews(newNews){
      if (newNews.length) {
          if (newNews && newNews[0].newsType === 'lineup') {
              var lineup = newNews[0].newsMessage.split('<br/>');
              if (lineup.length) {
                  news.lineup = {
                      formation: lineup[0],
                      goal: lineup[1],
                      defense: lineup[2],
                      middle: lineup[3],
                      attack: lineup[4]
                  };
              }
              newNews = newNews.splice(1);
          }
          if (newNews.length) {
              for (var i = 0; i < newNews.length; i++) {
                  var singleNews = NewsModel.new(newNews[i]);
                  if (!news[singleNews.category]) {
                      news[singleNews.category] = [];
                  }
                  news[singleNews.category].push(singleNews);
                  news.global.push(singleNews);
              }
          }
          self.news = news;
      }
    }

}

'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsCountdown', function ($moment, $interval) {
    return {
        name: 'dfsCountdown',
        templateUrl: 'dfsCountdown.html',
        replace: true,
        scope: {
            deadline: '@',
            countdownSize: '@'
        },
        link: function (scope) {
            var diff, rotation;

            scope.leftRotation = 0;
            scope.rightRotation = 0;
            scope.markerRotation = 0;
            scope.isTimeShort = false;

            updateTimer();

            var interval = $interval(function () {
                updateTimer();
            }, 1000);

            function updateTimer() {
                diff = $moment(parseInt(scope.deadline)).diff();

                if (diff > 0) {
                    var dur = $moment.duration(diff);
                    var days = dur.days().toString();
                    scope.timerDays = days.length > 1 ? days : '0' + days;
                    scope.timerTime = $moment.utc(diff).format('HH:mm:ss');
                    scope.countdownActive = true;
                    rotation = (dur.asHours() % 24) / 24 * 360;
                    scope.markerRotation = -1 * rotation;
                    if (rotation >= 180) {
                        scope.leftRotation = 0;
                        scope.rightRotation = 360 - rotation;
                    } else {
                        scope.rightRotation = 180;
                        scope.leftRotation = 180 - rotation;
                    }
                    if (dur.asHours() < 3 && !scope.isTimeShort) {
                        scope.isTimeShort = true;
                    }

                } else {
                    stopInterval();
                    scope.countdownActive = false;
                }
            }

            function stopInterval() {
                if (angular.isDefined(interval)) {
                    $interval.cancel(interval);
                    interval = undefined;
                }
            }

            scope.$on('$destroy', function () {
                stopInterval();
            });
        }
    };
});
'use strict';

/* @ngInject */
angular.module('bm.news.content').controller('NewsContentCtrl', function($timeout, SessionService, ClubService, NewsInfoService) {
    var self = this;
    this.ui = {
        openComments: {},
        comment: '',
        newsLimit: 10
    };

    if (!SessionService.getLastVisitHome()) {
        this.lastTimeVisitHome = 0;
    } else {
        this.lastTimeVisitHome = SessionService.getLastVisitHome();
    }

    SessionService.setLastVisitHome(Date.now());

    this.toggleComments = function(news) {
        if (!this.ui.openComments[news.id]) {
            news.getComments()
                .then(function() {
                    $timeout(function () {
                        self.ui.comment = '';
                        for (var newsId in self.ui.openComments) {
                            self.ui.openComments[newsId] = false;
                        }
                        self.ui.openComments[news.id] = true;
                    });
                });
        } else {
            this.ui.openComments[news.id] = false;
        }
    };

    this.addComment = function(commentText, news) {
        if (!commentText || !commentText.length) {
            return;
        }
        news.addComment(commentText, ClubService.myClub)
            .then(function() {
                self.ui.comment = '';
            });
    };

    var offset = 10;
    this.getNews = function(limit){
      let that = this;
      offset +=limit;
      NewsInfoService.getAllNews(limit, offset, function(){
        that.ui.newsLimit=that.ui.newsLimit+10;
      });

    };

});

'use strict';

/* @ngInject */
angular.module('bm.squad.box.reserve', []).directive('bmSquadBoxReserve', function (SquadService, ngDragDropService) {
    return {
        name: 'bmSquadBoxReserve',
        scope: {
            players: '=bmSquadBoxReserve'
        },
        bindToController: true,
        restrict: 'AE',
        templateUrl: 'squad-box-reserve-component.html',
        replace: true,
        link: function(scope) {

            scope.onDrop = function(event, draggableItem) {
                var player = ngDragDropService.draggableScope.player;
                SquadService.movePlayers(player);
                draggableItem.helper.css('left', '0px');
                draggableItem.helper.css('top', '0px');
            };

            scope.droppableOptions = {
                onDrop: 'onDrop'
            };

            scope.dropOptions = {
                tolerance: 'pointer'
            };

        }
    };
});

require('./SquadBoxReserveService');

'use strict';


/* @ngInject */
function bmSquadStatisticsPassesTabsCtrl() {

   
   
};


angular.module('bm.squad.statistics.tabs.passes').controller('bmSquadStatisticsPassesTabsCtrl', bmSquadStatisticsPassesTabsCtrl);

'use strict';
angular.module('bm.tab-focus', []);

require('./TabFocusProvider');

angular.module('bm.tab-focus').run(function(TabFocus, UserService) {
    TabFocus.init(function() {
        if (!UserService.isLoggedIn()) {
            UserService.destroySession();
        }
    });
});

'use strict';

angular.module('bm.progress', []).directive('bmProgress', function () {
	return {
	    name: 'bmProgress',
	    templateUrl: 'progress-component.html',
	    replace: true,
        transclude: true,
		scope: {
		    'max': '=progressMax',
		    'val': '=progressVal',
            'hideLabel': '=progressHideLabel'
		}
	};
});

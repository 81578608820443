'use strict';

require('./bm.squad.player/bm.squad.player');
require('./bm.squad.slot/bm.squad.slot');
require('./bm.squad.statistics/bm.squad.statistics.tabs.points');
require('./bm.squad.statistics/bm.squad.statistics.tabs.passes');
require('./bm.squad.statistics/bm.squad.statistics.tabs.duels');
require('./bm.squad.statistics/bm.squad.statistics.tabs.shots');
require('./bm.squad.statistics/bm.squad.statistics.tabs.touches');
require('./bm.squad.statistics/bm.squad.statistics.tabs.goals');
require('./bm.squad.statistics/bm.squad.statistics.tabs');

/* @ngInject */
angular.module('bm.squad.common', [
    'bm.squad.player',
    'bm.squad.slot',
    'bm.squad.statistics',
    'bm.squad.statistics.tabs.points',
    'bm.squad.statistics.tabs.passes',
    'bm.squad.statistics.tabs.duels',
    'bm.squad.statistics.tabs.shots',
    'bm.squad.statistics.tabs.touches',
    'bm.squad.statistics.tabs.goals'
]);

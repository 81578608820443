'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsLoader', function () {
    return {
        name: 'dfsLoader',
        templateUrl: 'dfsLoader.html',
        replace: true,
        scope: {
            'loaderSize': '@'
        },
        link: function (scope) {
            if (!scope.loaderSize) {
                scope.loaderSize = '100px';
            }
        }
    };
});
'use strict';

angular.module('kicker.footer', []).directive('kickerFooter', function () {
    return {
        name: 'kickerFooter',
        templateUrl: 'kickerFooter.html',
        controller: 'kickerFooterCtrl',
        replace: true,
        scope: {
            'fullWidth': '@',
            'responsive': '@'
        }
    };
});

require('./kickerFooterCtrl');

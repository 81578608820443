'use strict';

require('./ShareModalCtrl');
require('../google/ShortUrlService');

/* @ngInject */
angular.module('bm.share-modal').service('ShareModal', function($modal, $q, ShareService, bulimaConfig, UserService) {

    this.open = openMe;
    var url = bulimaConfig.APP_URL + '/#/registration?userhash=' + UserService.getManagerInfo().user.userhash;

    function openMe() {
        if (ShareService.doShowShare()) {
            return $modal.open({
                templateUrl: 'share-modal.html',
                controller: 'ShareModalCtrl',
                size: 'shareModal',
                resolve: {
                    /* @ngInject */
                    shareUrl: function(ShortUrlService) {
                        return ShortUrlService.shorten(url);
                    }
                }
            }).result;
        } else {
            return $q.reject();
        }
    }
});

'use strict';

/* @ngInject */
angular.module('bm.squad').controller('SquadCtrl', function (
    $q,
    $scope,
    $filter,
    $modal,
    bulimaConfig,
    SquadService,
    ReserveDispatcher,
    MatchDayService,
    CoTrainer,
    Notifier,
    ClubService,
    UserService) {

    var self = this;
    var club = $scope.bmSquad;
    SquadService.init(club.players, club.formation, club.captain);
    var players = SquadService.getPlayers();
    var formation = SquadService.getFormation();
    var stateChange = false;

    MatchDayService.getNextMatches().then(function (matches) {
        self.matches = matches;
    });

    UserService.getUserCurrentInfo().then(function (data) {
        var time = new Date().getTime();
        self.currentUserIsPremium = (data.premiumStatusActiveTimestamp !== null && data.premiumStatusActiveTimestamp > time);
    });

    this.selectedFormation = formation.name;
    this.formation = players.formation;

    this.coTrainer = CoTrainer;

    this.players = players.squadPlayers;
    this.filteredPlayers = this.reserve = players.reservePlayers;
    this.bench = players.benchPlayers;

    this.benchUnlock = ClubService.myClub.amountSubstituteBenchSlots;
    var benchUnlockCallbackId = ReserveDispatcher.register(function(payload) {
        if (payload.actionType === 'unlock_bench') {
            self.benchUnlock += payload.value;
        }
    });

    this.enableSaveBtn = false;

    $scope.$watch(function() {
            return SquadService.isSaved(false);
        },
        function(newVal, oldVal) {
            if (newVal !== oldVal && !stateChange) {
                self.enableSaveBtn = !newVal;
            }
        });

    this.captain = SquadService.getCaptain();

    this.onDropCaptain = function(player) {
        SquadService.setCaptain(player);
        self.captain = SquadService.getCaptain();
    };

    var captainCallbackId = ReserveDispatcher.register(function(payload) {
        if (payload.actionType === 'captain_update') {
            self.captain = payload.captain;
        }
    });

    var reserveCallbackId = ReserveDispatcher.register(function(payload) {
        if (payload.actionType === 'reserve_update') {
            self.reserve = payload.players;
            self.filteredPlayers = $filter('filter')(self.reserve, positionFilter);
        }
    });

    SquadService.getFormations().then(function(formations) {
        self.formations = formations;
    });

    this.formationChanged = function(formation) {
        if (angular.fromJson(formation) !== Object) {
            formation = angular.fromJson(formation);
        }
        this.formation = SquadService.setFormation(formation.name);
        SquadService.renderPlayers();
        players = SquadService.getPlayers();
        this.players = players.squadPlayers;
        this.reserve = players.reservePlayers;
        self.filteredPlayers = $filter('filter')(self.reserve, positionFilter);
    };

    this.onDrop = function(player, slot) {
        SquadService.movePlayers(player, slot);
    };

    var ui = self.ui = {
        selectedPosition: null,
        isInternational: bulimaConfig.INTERNATIONAL
    };

    function positionFilter(player) {
        if (!ui.selectedPosition || ui.selectedPosition === player.position.id) {
            return true;
        } else {
            return false;
        }
    }

    this.filter = function(positionType) {
        switch (positionType) {
            case 'ALL':
                ui.selectedPosition = null;
                self.filteredPlayers = self.reserve;
                return;
            case 'FW':
                ui.selectedPosition = 4;
                break;
            case 'MF':
                ui.selectedPosition = 3;
                break;
            case 'DF':
                ui.selectedPosition = 2;
                break;
            case 'GK':
                ui.selectedPosition = 1;
                break;
        }

        self.filteredPlayers = $filter('filter')(self.reserve, positionFilter);

    };

    var offStateChange = $scope.$on('$stateChangeStart', function(event) {
        stateChange = true;
        if (!SquadService.isSaved(true)) {
            event.preventDefault();
        }
    });

    $scope.$on('$destroy', function() {
        Notifier.close();
        offStateChange();
        ReserveDispatcher.unregister(captainCallbackId);
        captainCallbackId = null;
        ReserveDispatcher.unregister(benchUnlockCallbackId);
        benchUnlockCallbackId = null;
        ReserveDispatcher.unregister(reserveCallbackId);
        reserveCallbackId = null;
        self = null;
        players = null;
        formation = null;
        ui = null;
    });

    this.saveFormation = function() {
        SquadService.saveFormation(ClubService.getClubInfo().managerName + '_lineupFlag')
            .then(function() {
                self.enableSaveBtn = false;
                ClubService.getClub();
            });
    };

    this.openCotrainerModal = function () {
        $modal.open({
            templateUrl: 'squad-lineup-cotrainer-modal.html',
            scope: $scope,
            size: 'auction'
        }).result.then(function (res) {
            self.coTrainer.activate(res);
        });
    };
});

    'use strict';


    angular.module('bm.tab-focus').provider('TabFocus', function() {
        /////////////////////////////////////////
        // main visibility API function
        // check if current tab is active or not
        var vis = (function() {
            var stateKey,
                eventKey,
                keys = {
                    hidden: 'visibilitychange',
                    webkitHidden: 'webkitvisibilitychange',
                    mozHidden: 'mozvisibilitychange',
                    msHidden: 'msvisibilitychange'
                };
            for (stateKey in keys) {
                if (stateKey in document) {
                    eventKey = keys[stateKey];
                    break;
                }
            }
            return function(c) {
                if (c) {
                    document.addEventListener(eventKey, c);
                }
                return !document[stateKey];
            };
        })();

        var focusinHandler = angular.noop;
        var enabled = false;

        /////////////////////////////////////////
        // check if current tab is active or not
        vis(function() {

            if (vis()) {

                // the setTimeout() is used due to a delay
                // before the tab gains focus again, very important!
                setTimeout(function() {
                    if (enabled) {
                        focusinHandler();
                    }

                }, 300);

            } else {

                //console.log('No focus tab ');
            }
        });

        /////////////////////////////////////////
        // check if browser window has focus
        var notIE = (document.documentMode === undefined),
            isChromium = window.chrome;

        if (notIE && !isChromium) {

            // checks for Firefox and other  NON IE Chrome versions

            $(window).on('focus', function() {
                if (enabled) {
                    focusinHandler();
                }
            });

        } else {

            // checks for IE and Chromium versions
            if (window.addEventListener) {

                // bind focus event
                window.addEventListener('focus', function() {
                    if (enabled) {
                        focusinHandler();
                    }
                });

            } else {

                window.attachEvent('focus', function() {
                    if (enabled) {
                        focusinHandler();
                    }
                });
            }
        }
        this.$get = function() {
            var tabFocus = {};

            tabFocus.init = function(callback) {
                focusinHandler = callback;
            };

            tabFocus.turnOn = function() {
                enabled = true;
            };

            tabFocus.turnOff = function() {
                enabled = false;
            };

            return tabFocus;
        };

    });

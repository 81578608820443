'use strict';

require('./bm.squad.lineup/bm.squad.lineup');
require('./bm.squad.fixtures/bm.squad.fixtures');
require('./bm.squad.box/bm.squad.box');
require('./bm.squad.common/bm.squad.common');

/* @ngInject */
angular.module('bm.squad', [
    'bm.squad.lineup',
    'bm.squad.box',
    'bm.squad.fixtures',
    'bm.squad.common'
]).directive('bmSquad', function() {
    return {
        templateUrl: 'squad-component.html',
        controller: 'SquadCtrl',
        controllerAs: 'S',
        scope: {
            'bmSquad': '='
        }
    };
});

require('./SquadCtrl');
require('./SquadService');
require('./SquadSaveModalCtrl');


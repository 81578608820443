'use strict';

/* @ngInject */
angular.module('bm.squad.lineup.field').factory('SquadLineupFieldService',function () {

    var field = [];

    /* Initialize the field array */
    function init(length) {
        field = new Array(length);
    }

    /* Get all field players
     * index - if defined, get by id
     * pop - if true, pops the element */
    function get(index, pop) {
        if (index === undefined) {
            return field;
        } else {
            var found = field[index];
            if (pop) {
                del(index);
            }
            return found;
        }
    }

    /* Get all players unformatted */
    function getAll() {
        var allPlayers = [];
        for (var i = 0; i < field.length; i++) {
            if (field[i] !== undefined) {
                allPlayers.push(field[i]);
            }
        }
        return allPlayers;
    }

    function set(players) {
        field = players;
    }

    /* Delete all field players
     * id - if defined, deletes by id */
    function del(index) {
        if (index === undefined) {
            field = [];
        } else {
            field[index] = null;
        }
    }

    /* Add a player to field players and return a replaced player */
    function put(player, index) {
        var replacedPlayer = null;
        if (field[index]) {
            replacedPlayer = field[index];
        }
        field[index] = player;
        return replacedPlayer;
    }

    return {
        init: init,
        get: get,
        getAll: getAll,
        set: set,
        del: del,
        put: put
    };
});

'use strict';

require('./BugReportCtrl');

/* @ngInject */
angular.module('bm.bug-report').service('BugReportModal', function($modal) {
    this.open = openModal;

    function openModal() {
        return $modal.open({
            templateUrl: 'bug-report-modal.html',
            controller: 'BugReportCtrl',
            size: 'bugReport'
        });
    }
});


'use strict';

/* @ngInject */
angular.module('bm.googletag').directive('bmGoogletag', function ($timeout) {
    return {
        restrict: 'EA',
        templateUrl: 'googletag.html',
        replace: true,
        controller: 'GoogletagCtrl',
        scope: {
            id: '@tagid',
            variant: '@',
            targeting: '@'
        },
        link: function (scope) {
            scope.hide = true;
            if (scope.isInternational && !scope.isPremiumUser) {
                scope.hide = false;
                if (scope.slot && scope.targeting) {
                    $timeout(function () {
                        googletag.cmd.push(function () {
                            googletag.defineSlot(scope.slot.browser, scope.slot.screens, scope.id)
                                .addService(googletag.pubads())
                                .setTargeting('BS_Section', [scope.targeting])
                                .setCollapseEmptyDiv(true, true);
                            googletag.enableServices();
                            googletag.display(scope.id);
                        });
                    });
                }
                scope.$on('$destroy', function () {
                    if (googletag.destroySlots) {
                        googletag.destroySlots();
                    }
                });
            }
        }
    };
});
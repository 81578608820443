'use strict';

require('./bm.squad.lineup.field/bm.squad.lineup.field');
require('./bm.squad.lineup.bench/bm.squad.lineup.bench');
require('./bm.squad.lineup.captain/bm.squad.lineup.captain');
require('./bm.squad.lineup.formation/bm.squad.lineup.formation');

/* @ngInject */
angular.module('bm.squad.lineup', [
    'bm.squad.lineup.field',
    'bm.squad.lineup.bench',
    'bm.squad.lineup.captain',
    'bm.squad.lineup.formation'
]).directive('bmSquadLineup', function() {
    return {
        templateUrl: 'squad-lineup-component.html',
        bindToController: true
    };
});
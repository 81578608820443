'use strict';

require('../bm.bug-report/BugReportModal');

/* @ngInject */
function kickerFooterCtrl($scope, BugReportModal) {

    $scope.openBugReport = function() {
        BugReportModal.open();
    };
}

angular.module('kicker.footer').controller('kickerFooterCtrl', kickerFooterCtrl);

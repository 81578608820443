(function(window, angular, undefined) {
    'use strict';

    /**
     * @ngdoc overview
     * @name angulartics.localytics
     * Enables analytics support for Localytics (http://localytics.com)
     */
    angular.module('angulartics.localytics', ['angulartics'])
        .config(['$analyticsProvider', 'bulimaConfig', function ($analyticsProvider, bulimaConfig) {

            $analyticsProvider.settings.pageTracking.trackRelativePath = true;

            /**
             * Register the page tracking function.
             */
            $analyticsProvider.registerPageTrack(function(path) {
                var localytics = window.LocalyticsGlobal;
                if (!bulimaConfig.INTERNATIONAL && !window[localytics]) {
                    return;
                }
                var pathObject = {
                  page: path
                };
                window[localytics]('tagEvent', 'Page View', pathObject);
            });

            /**
             * Register the Localytics event tracking function with the following parameters:
             * @param {string} action Required 'action' (string) associated with the event
             * this becomes the event name
             * @param {object} properties Additional attributes to be associated with the
             * event. See http://support.localytics.com/Integration_Overview#Event_Attributes
             *
             */
            $analyticsProvider.registerEventTrack(function(action, properties) {
                var localytics = window.LocalyticsGlobal;
                if (!bulimaConfig.INTERNATIONAL && !window[localytics]) {
                    return;
                }
                window[localytics]('tagEvent', action, properties);
            });

        }]);

})(window, window.angular);

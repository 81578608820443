'use strict';

angular.module('bm.squad.statistics.tabs.touches', []).directive('bmSquadStatisticsTabsTouches', function () {
    return {
        name: 'bmSquadStatisticsTabsTouches',
        templateUrl: 'bmSquadStatisticsTouchesTabs.html',
        controller: 'bmSquadStatisticsTouchesTabsCtrl',
        replace: true,
        scope: {
            showGraphClick : '&',
            datacolumnsTouches : '=datacolumns',
            datapointsTouches : '=datapoints',
            showData : '&',
            onrendered :'&onrendered',
            chartInformation:'=chartInformation'
        }
    };
});

require('./bmSquadStatisticsTouchesTabsCtrl');


'use strict';

angular.module('dfs.shop-promo').service('DfsShopPromoService', DfsShopPromoService);

/* @ngInject */
function DfsShopPromoService($http, bulimaConfig) {

    function getBanners(stateName) {
        var res = {
            state: [],
            all: []
        };
        return $http.post(bulimaConfig.BASE_URL + '/commonservice/getBanner').then(function (response) {
            if (stateName) {
                for (var i = 0; i < response.data.length; i++) {
                    if (stateName.indexOf(response.data[i].name) >= 0) {
                        res.state.push(response.data[i]);
                    }
                    if (response.data[i].name === 'all') {
                        res.all.push(response.data[i]);
                    }
                }
                return res;
            } else {
                return response.data;
            }
        });
    }

    this.getBanners = getBanners;
}

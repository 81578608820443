'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsSref', function ($state) {
    return {
        name: 'dfsSref',
        restrict: 'A',
        link: function (scope, element, attr) {
            if (attr.dfsSrefActive && $state.current.name.indexOf(attr.dfsSref) >= 0) {
                element.toggleClass(attr.dfsSrefActive);
            }
            element.on('click', function (e) {
                e.preventDefault();
                if (attr.dfsSref) {
                    var stateName = $state.current.name.split('.')[0] + '.' + attr.dfsSref;
                    $state.go(stateName);
                }
            });
        }
    };
});
'use strict';

require('./WEB_TUTORIALS_IMAGES');

angular.module('bm.tutorials').factory('WebTutorialsService', WebTutorialsService);

/* @ngInject */
function WebTutorialsService($http, $q, bulimaConfig, LocalizationService, WEB_TUTORIALS_IMAGES, UserService, ModalService) {

    var webTutorialsService = {};

    var version;
    if (LocalizationService.getLanguageKey() === LocalizationService.languages.DE) {
        version = 'de';
        if (bulimaConfig.INTERNATIONAL) {
            version += '-int';
        } else {
            version += '-sport1';
        }
    } else {
        version = 'en';
    }

    webTutorialsService.getSeenTutorials = function() {
        return UserService.getSeenTutorials();
    };

    webTutorialsService.setSeenTutorial = function(tutorialImg) {
        return $http.post(bulimaConfig.BASE_URL + '/writeaccountservice/finishedTutorial', tutorialImg).then(function() {
            var user = UserService.getManagerInfo();
            user.user.tutorials.push(tutorialImg);
            return UserService.setManagerInfo(user);
        });
    };

    webTutorialsService.hadSeenTutorials = function(page) {
        var tutorials = this.getSeenTutorials();
        if (!tutorials) {
            return $q.when();
        }
        for (var i = 0; i < tutorials.length; i++) {
            if (tutorials[i].indexOf(page) !== -1) {
                return $q.when();
            }
        }
        return openTutorial(page);
    };

    function openTutorial(page) {
        var images = getImagesForPage(page);
        if (!images) {
            return $q.when();
        }
        return ModalService.openModalPopUp('webTutorials.html', null, 'tutorial', images)
            .finally(function() {
                webTutorialsService.setSeenTutorial(page);
            });
    }

    function getImagesForPage(page) {
        return WEB_TUTORIALS_IMAGES[version][page];
    }

    return webTutorialsService;
}

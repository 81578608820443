'use strict';

require('./ShareService');

/* @ngInject */
angular.module('bm.share-modal')
    .controller('ShareModalCtrl',
        function($scope,
            $state,
            $modalInstance,
            $translate,
            bulimaConfig,
            UserService,
            ShareService,
            Notifier,
            shareUrl) {

            $scope.invite = invite;
            $scope.addRow = addRow;
            $scope.notNow = notNow;
            $scope.shareOnFb = shareOnFb;
            $scope.usersData = [];
            $scope.shareUrl = shareUrl;
            $scope.shareText = $translate.instant('SHARE_MODAL.TWITTER_SHARE_TEXT');
            $scope.data = {
                dontShowAgain: false
            };

            var tempUser = {
                email: ''
            };

            $scope.usersData.push(angular.copy(tempUser));

            function addRow(form) {
                form.$showErrors = false;
                for (var index in $scope.usersData) {
                    var user = $scope.usersData[index];
                    if (!user.email) {
                        form.$showErrors = true;
                        return;
                    }
                }
                $scope.usersData.push(angular.copy(tempUser));
            }

            function notNow() {
                if ($scope.data.dontShowAgain) {
                    ShareService.dontShowAgain();
                }
                $modalInstance.dismiss();
            }

            function shareOnFb() {
                FB.ui({
                    method: 'share',
                    href: $scope.shareUrl
                }, function() {});
            }

            function invite(users, form) {
                if (!users[0].email) {
                    form.$showErrors = true;
                    return;
                }
                form.$showErrors = false;
                ShareService.invite(users).then(function() {
                    Notifier.success('SHARE_MODAL.NOTIFICATION_INVITE_SUCCESS');
                });
            }
        });

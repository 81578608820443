'use strict';
angular.module('zeroclipboard', [])
    .provider('zeroclipboard', function() {

        // default config
        var _zeroclipConfig = {
            buttonClass: '',
            swfPath: 'components/zeroclipboard/zeroclipboard.swf',
            trustedDomains: [window.location.host],
            cacheBust: true,
            forceHandCursor: false,
            zIndex: 999999999,
            debug: true,
            title: null,
            autoActivate: true,
            flashLoadTimeout: 30000,
            hoverClass: 'hover',
            activeClass: 'active'
        };
        var _overrideConfig = true;

        this.setZcConf = function(zcConf) {
            angular.extend(_zeroclipConfig, zcConf);
        };
        this.setOverrideConfig = function(overrideConfig) {
            _overrideConfig = overrideConfig;
        };
        this.$get = function() {
            return {
                zeroclipConfig: _zeroclipConfig,
                overrideConfig: _overrideConfig
            };
        };
    })

.directive('bmZeroclip', ['$document', '$window', 'zeroclipboard',
    function($document, $window, zeroclipboard) {

        var zeroclipConfig = zeroclipboard.zeroclipConfig || {};
        var ZeroClipboard = $window.ZeroClipboard;

        return {
            scope: {
                onCopied: '&zeroclipCopied',
                onError: '&?zeroclipOnError',
                onBeforeCopy: '&?zeroclipOnBeforeCopy',
                client: '=?uiZeroclip',
                value: '=zeroclipModel',
                text: '@zeroclipText'
            },
            link: function(scope, element) {

                var btn = element[0];
                var _completeHnd;

                // config
                if (zeroclipboard.overrideConfig) {
                    ZeroClipboard.config(zeroclipConfig);
                }

                if (angular.isFunction(ZeroClipboard)) {
                    scope.client = new ZeroClipboard(btn);
                }

                scope.$watch('value', function(v) {
                    if (v === undefined) {
                        return;
                    }
                    element.attr('data-clipboard-text', v);
                });

                scope.$watch('text', function(v) {
                    element.attr('data-clipboard-text', v);
                });

                scope.client.on('aftercopy', _completeHnd = function(e) {
                    scope.$apply(function() {
                        scope.onCopied({
                            $event: e
                        });
                    });
                });

                scope.client.on('error', function(e) {
                    if (scope.onError) {
                        scope.$apply(function() {
                            scope.onError({
                                $event: e
                            });
                        });
                    }
                    ZeroClipboard.destroy();
                });

                scope.client.on('beforecopy', function(e) {
                    if (scope.onBeforeCopy) {
                        scope.$apply(function() {
                            scope.onBeforeCopy({
                                $event: e
                            });
                        });
                    }
                });

                scope.$on('$destroy', function() {
                    scope.client.off('complete', _completeHnd);
                    scope.client = null;
                    element.off();
                    ZeroClipboard.destroy();
                });
            }
        };
    }
]);

'use strict';

/* @ngInject */
angular.module('dfs.common', []);

require('./dfs.profile-box/dfs.profile-box');
require('./dfs.item-box/dfs.item-box');
require('./dfs.progress-box/dfs.progress-box');
require('./dfs.challenge-box/dfs.challenge-box');
require('./dfs.hexagon/dfs.hexagon');
require('./dfs.countdown/dfs.countdown');
require('./dfs.coin/dfs.coin');
require('./dfs.progress-bar/dfs.progress-bar');
require('./dfs.bg-change/dfs.bg-change');
require('./dfs.checkbox/dfs.checkbox');
require('./dfs.href/dfs.href');
require('./dfs.loader/dfs.loader');
require('./dfs.stop-event/dfs.stop-event');
require('./dfs.sref/dfs.sref');
require('./dfs.filter-select/dfs.filter-select');
require('./dfs.max-chars/dfs.max-chars');
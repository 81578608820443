'use strict';

angular.module('dfs.common').directive('dfsProgressBar', function () {
	return {
	    name: 'dfsProgressBar',
	    templateUrl: 'dfsProgressBar.html',
	    replace: true,
        transclude: true,
		scope: {
		    'max': '=progressMax',
		    'val': '=progressVal'
		}
	};
});

'use strict';

angular.module('bm.remaining-time', []).directive('bmRemainingTime', function ($moment, $interval) {
    return {
        name: 'bmRemainingTime',
        templateUrl: 'remaining-time-component.html',
        scope: {
            'time': '=bmRemainingTime',
            'fulltime': '@',
            'isDeadline': '@'
        },
        link: function (scope, element) {
            updateTime();
            var timeout = $interval(updateTime, 1000);

            element.on('$destroy', function () {
                $interval.cancel(timeout);
            });

            function updateTime() {
                if (scope.fulltime === 'true') {
                    scope.remainingTime = $moment().to(scope.time, true);
                } else {
                  scope.time = scope.time-1000;
                  var toTime;
                  var duration = scope.time - new Date().getTime();
                  if (scope.isDeadline === 'true') {
                      toTime = $moment.duration(duration);
                  } else {
                      toTime = $moment.duration(scope.time);
                  }
                  var toDays = parseInt(toTime.asDays()),
                      toHours = parseInt(toTime.asHours()),
                      toMinutes = parseInt(toTime.asMinutes()),
                      toSeconds = parseInt(toTime.asSeconds());

                    if(toMinutes !== 0){
                        $interval.cancel(timeout);
                    }

                    scope.remainingTime = '';
                    if (toDays !== 0 && toHours > 48) {
                        scope.remainingTime = toDays + 'd';
                    } else {
                        if (toHours !== 0) {
                            scope.lessThanThreeHours = toHours < 3;
                            scope.remainingTime = toHours + 'h';
                        } else {
                            scope.lessThanThreeHours = true;
                            if (toMinutes !== 0) {
                                scope.remainingTime = toMinutes + 'm';
                            } else {
                                if (toSeconds>30) {
                                    scope.remainingTime = '< 1m';
                                } else {
                                    scope.remainingTime = '< 30s';
                                }                                    
                            }
                        }
                    }
                }
            }
        }
    };
});

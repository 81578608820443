'use strict';

/* @ngInject */
angular.module('bm.news').factory('CommentModel', function(BaseClass) {

    CommentModel.inherits(BaseClass);

    function CommentModel(comment) {
        this.id = comment.id;
        this.club = comment.fantasyClub;
        this.date = comment.createDate;
        this.text = comment.message;
    }

    return CommentModel;
});

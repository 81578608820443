'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsProgressBox', function () {
    return {
        name: 'dfsProgressBox',
        templateUrl: 'dfsProgressBox.html',
        transclude: true,
        replace: true,
        scope: {
            itemProgress: '@',
            itemLevel: '@',
            itemSize: '@'
        },
        link: function (scope) {
            var rotation;

            scope.$watch('itemProgress', function (newV, oldV) {
                if (!rotation || newV !== oldV) {
                    rotation = newV * 360;
                    scope.markerRotation = -1 * rotation;
                    scope.leftRotation = rotation >= 180 ? 0 : 180 - rotation;
                    scope.rightRotation = rotation >= 180 ? 360 - rotation : 180;
                }
            });
            
        }
    };
});
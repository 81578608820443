'use strict';

angular.module('bm.shop-article', []).directive('bmShopArticle', function () {
	return {
	    name: 'bmShopArticle',
		templateUrl: 'shop-article-component.html',
		replace: true,
        transclude: true,
		scope: {
		    'articleBuy': '&',
		    'articleTitle': '@',
		    'articleImageUrl': '@',
            'articleDiscount': '@',
            'articleOldPrice': '@',
		    'articleBestPrice': '@',
		    'articleBtnText': '@',
		    'articlePrice': '@',
            'articleShowCurrency': '='
		}
	};
});

'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsItemBox', function () {
    return {
        name: 'dfsItemBox',
        templateUrl: 'dfsItemBox.html',
        replace: true,
        scope: {
            itemType: '@',
            itemNumber: '@'
        }
    };
});
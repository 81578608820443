'use strict';

var $moment;

if (window.hasOwnProperty('moment')) {
    $moment = window.moment;

    if ($moment().hasOwnProperty('format') || window.hasOwnProperty('moment')) {
        window.moment = undefined;
    }

    $moment.relativeTimeThreshold('m', 61);
    $moment.relativeTimeThreshold('h', 24);
}

require('./bm.common');

angular.module('bm.common')
    .constant('$moment', $moment);

'use strict';

var $injector = angular.injector(['ng', 'angularLocalStorage', 'bulima.config']);
var $document = angular.element(document);

/* @ngInject */
$injector.invoke(function($q, $http, bulimaConfig, storage) {
    var startDefered = $q.defer();

    startDefered.promise.then(function(userData) {
        angular.module('bulima.config').constant('MANAGER_INFO', userData);
    }, function() {
        angular.module('bulima.config').constant('MANAGER_INFO', {});
    }).finally(function() {
        $document.ready(function() {
            angular.bootstrap(document, ['bulima']);
        });
    });

    var token = storage.get(bulimaConfig.TOKEN_NAME);
    if (!token) {
        startDefered.reject();
    } else {

        var url = bulimaConfig.BASE_URL + '/readaccountservice/getmanager/';
        $http({
            method: 'post',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(function(response) {
            startDefered.resolve(response.data);
        }, function(error) {
            startDefered.reject(error);
        });

    }
});

'use strict';

/* @ngInject */
angular.module('bm.league-selector').controller('LeagueSelectorCtrl', function ($scope, $filter) {
    $scope.ui = {
        leagueName: '',
        showPrivateLeagues: false,
        showLeagueDetails: null,
        itemsPerPage: $scope.pagination || 10,
        currentPage: 0
    };

    $scope.page = [];

    $scope.shownPages = [];

    var filteredLeagues = [];

    $scope.updateFiltered = function () {
        $scope.ui.currentPage = 0;
        filteredLeagues = $filter('filter')($scope.leagues, { 'private': $scope.ui.showPrivateLeagues, 'name': $scope.ui.leagueName });
        updatePage();
        updateShownPages();
    };

    $scope.updatePrivateLeagues = function (flag) {
        $scope.ui.showPrivateLeagues = flag;
        $scope.updateFiltered();
    };

    $scope.getLeagueSettings = function (league) {
        league.getSettings().then(function () {
            $scope.ui.showLeagueDetails = league;
        });
    };

    $scope.prevPage = function () {
        if ($scope.ui.currentPage > 0) {
            $scope.ui.currentPage--;
            updatePage();
            updateShownPages();
        }
    };

    $scope.prevPageDisabled = function () {
        return $scope.ui.currentPage === 0;
    };

    $scope.pageCount = function () {
        return filteredLeagues.length !== 0 ? Math.ceil( filteredLeagues.length / $scope.ui.itemsPerPage) - 1 : 0;
    };

    $scope.nextPage = function () {
        if ($scope.ui.currentPage < $scope.pageCount()) {
            $scope.ui.currentPage++;
            updatePage();
            updateShownPages();
        }
    };

    $scope.nextPageDisabled = function () {
        return $scope.ui.currentPage === $scope.pageCount();
    };

    $scope.gotoPage = function (pageNumber) {
        $scope.ui.currentPage = parseInt(pageNumber, 10);
        updatePage();
        updateShownPages();
    };

    $scope.updateFiltered();

    function updatePage() {
        var start = parseInt($scope.ui.currentPage, 10) * parseInt($scope.ui.itemsPerPage);
        var end = start + parseInt($scope.ui.itemsPerPage, 10);
        $scope.page = filteredLeagues.slice(start, end);
    }

    function updateShownPages() {
        var curr = $scope.ui.currentPage,
            max = $scope.pageCount(),
            start = curr - 2 <= 0 ? 0 : max - curr >= 2 ? curr - 2 : curr - (4 - (max - curr)) >= 0 ? curr - (4 - (max - curr)) : 0,
            end = start + 4 <= max ? start + 4 : max;

        $scope.shownPages = [];

        for (var i = start; i <= end; i++) {
            $scope.shownPages.push(i);
        }
    }
});

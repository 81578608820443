'use strict';

require('../localytics/llProvider');
require('../google/gaProvider');

/* @ngInject */
angular.module('bm.analytics', ['localytics', 'google'])
    .config(function(bulimaConfig, llProvider, gaProvider) {
        llProvider.init(bulimaConfig.LOCALYTICS_ID);
        gaProvider.init(bulimaConfig.GOOGLE_ANALYTICS_ID);
    });

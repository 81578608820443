'use strict';

/* @ngInject */
angular.module('bm.squad.lineup.formation').factory('SquadLineupFormationService',function () {

    var formation;

    /* Get formation */
    function get() {
        return formation;
    }

    /* Set new formation and return old */
    function set(newFormation) {
        var oldFormation = formation;
        formation = format(newFormation);
        return oldFormation;
    }

    function format(formation) {
        var form;
        if (formation.name) {
            form = formation.name.split('-');
        } else {
            form = formation.split('-');
        }
        var lineSize;
        var tempFormation = [];
        tempFormation.squadSize = 0;
        if (form.length < 4) {
            tempFormation.push(1);
            tempFormation.squadSize += 1;
        }
        for (var i = 0; i < form.length; i++) {
            lineSize = +form[i];
            tempFormation.push(lineSize);
            tempFormation.squadSize += lineSize;
        }
        tempFormation.name = formation;
        return tempFormation;
    }

    return {
        get: get,
        set: set,
        format: format
    };
});

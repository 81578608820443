'use strict';

/* @ngInject */
angular.module('bm.news').controller('NewsComponentCtrl', function(NewsService) {
    var self = this;

    this.tabOpen = 'previewNews';

    self.news = NewsService.news;
});

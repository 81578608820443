'use strict';

/* @ngInject */
angular.module('bm.squad.slot', []).directive('bmSquadSlot', function (SwapFactory, ngDragDropService, ModalService, PremiumItemsService, TokensService) {
    return {
        name: 'bmSquadSlot',
        restrict: 'AE',
        scope: {
            player: '=bmSquadSlot',
            line: '=line',
            slot: '=slot',
            unlock: '=unlock',
            onDrop: '&onDrop',
            showCost: '@',
            allowAllPositions: '=allowAllPositions'
        },
        templateUrl: 'squad-slot-component.html',
        link: function(scope, element) {
            scope.droppableActive = false;
            scope.draggableActive = true;

            if (scope.line === 'captain') {
                scope.draggableActive = false;
            }

            scope.onDropHandler = function(event, draggableItem) {
                var player = ngDragDropService.draggableScope.player;
                if (player.slot !== scope.slot) {
                    scope.onDrop({
                        player: player,
                        slot: scope.slot
                    });
                }
                draggableItem.helper.css('left', '0px');
                draggableItem.helper.css('top', '0px');
            };

            function onSlotClick() { // TODO: Move click logic out of this directive. Or make another directive (which require bmSlot) to handle this.
                var availableBench = PremiumItemsService.benchPlace && PremiumItemsService.benchPlace.available ? PremiumItemsService.benchPlace.available : 0;
                scope.tokens = TokensService.amount;
                if (scope.unlock === false) {

                    if (availableBench > 0) {
                        var params = {
                            header: 'LINE_UP_UNLOCK_BENCH_MODAL.BENCH_SLOT',
                            body: 'LINE_UP_UNLOCK_BENCH_MODAL.ACTIVATE_BENCH',
                            btn: 'LINE_UP_UNLOCK_BENCH_MODAL.ACTIVATE_BENCH_BTN'
                        };
                        ModalService.openModalPopUp('unlock-bench-modal.html', 'UnlockSlotCtrl', '', params);
                    } else {
                        var itemCost = PremiumItemsService.getItem('benchPlace').price;
                        var param = {
                            header: 'LINE_UP_UNLOCK_BENCH_MODAL.BENCH_SLOT',
                            body: 'LINE_UP_UNLOCK_BENCH_MODAL.ACTIVATE_BENCH_FUSSI',
                            btn: itemCost
                        };
                        ModalService.openModalPopUp('unlock-bench-modal_no_items.html', 'UnlockSlotCtrl', '', param);
                    }
                }
            }

            element.on('click', onSlotClick);

            scope.droppableOptions = {
                onDrop: 'onDropHandler'
            };

            scope.dropOptions = { // TODO: Move configuration to provider so we can configure this in config phase and here only use it.
                tolerance: 'pointer',
                hoverClass: 'is-acceptable',
                activeClass: 'is-active'
            };

            var lineStore = {
                line: null
            };

            function handleLineChange(line) {
                if (scope.line === 0 || line === scope.line || scope.allowAllPositions) {
                    scope.droppableActive = true;
                } else {
                    scope.droppableActive = false;
                }
            }

            lineStore.dispatchToken = SwapFactory.register(function(payload) {
                if (payload.actionType === 'line_slot_activate') {
                    lineStore.line = payload.line;
                    // scope.shadowClass = ''; // TODO: See what is this for.
                    // if (payload.line && scope.line && scope.line !== 'captain') {
                    //     scope.shadowClass = payload.line === scope.line ? 'enabled' : 'disabled';
                    // }
                    scope.$apply(function() {
                        handleLineChange(payload.line);
                    });
                }
            });

            var captainCallbackId = SwapFactory.register(function(payload) {
                if (payload.actionType === 'captain_slot_activate') {
                    SwapFactory.waitFor([lineStore.dispatchToken]);
                    scope.$apply(function() {
                        if (scope.line === 'captain') {
                            scope.droppableActive = true;
                        }
                    });
                }
            });

            scope.$on('$destroy', function() {
                SwapFactory.unregister(captainCallbackId);
                SwapFactory.unregister(lineStore.dispatchToken);
            });
        }
    };
});

require('./UnlockSlotCtrl');

'use strict';

/* @ngInject */
angular.module('bm.squad.player', []).directive('bmSquadPlayer', function (SwapFactory, $modal, PlayerStatusFactory, $document) {
    return {
        name: 'bmSquadPlayer',
        scope: {
            player: '=bmSquadPlayer',
            isStatisticsCard: '=isStatisticsCard',
            draggable: '=draggable',
            isDraggable: '=isDraggable',
            disableDetails: '=disableDetails',
            showCost: '@',
            budget: '@',
            formationFlag: '@'
        }, 
        restrict: 'AE',
        templateUrl: function(elem, attr) {
            var isDraggable = attr.isDraggable;
            console.log(attr.isStatisticsCard);
            if (isDraggable === 'false') {
                return 'squad-player-component.html';
            } else {
                return 'squad-player-draggable-component.html';
            }
        },
        link: function(scope, element) {
            scope.$on('updatePlayerCost', updatePlayerCost);

            function updatePlayerCost(event, args) {
                scope.showCost = args.cost;
                scope.budget = args.budget;
            }

            function onPlayerClick() {
                if (scope.disableDetails === true) {
                    return;
                }
                $modal.open({
                    templateUrl: 'squad-player-details-modal.html',
                    scope: scope,
                    size: 'playerDetails' //Used as modal class ('modal-' + size). Ex. modal-auction;
                });
            }
            scope.data = {
                reportingIssue: null,
                url: null,
                player: null
            };
            scope.reportPlayerStatus = false;
            scope.switchStatusReport = function() {
                scope.reportPlayerStatus = !scope.reportPlayerStatus;
            };

            scope.reportStatus = function(data, form) {
                if (form.$invalid) {
                    form.$showErrors = true;
                } else {
                    if (!data.reportingIssue) {
                        data.reportingIssue = scope.player.isSlightlyInjured ? 'slightly injured' : (scope.player.isHurt) ? 'injured' : 'healthy';
                    }
                    form.$showErrors = false;
                    data.player = {
                        id: scope.player.id
                    };
                    PlayerStatusFactory.reportNewStatus(data).then(function() {
                        scope.data.url = null;
                        scope.reportPlayerStatus = false;
                        data.reportingIssue = scope.player.isSlightlyInjured ? 'slightly injured' : (scope.player.isHurt) ? 'injured' : 'healthy';
                    });
                }
            };

            element.on('click', onPlayerClick);

            scope.onDragStart = function () {
                if (scope.player) {
                    if ($document.width() < 768) {
                        var offset = $document.find('.field').offset().top;
                        $document.scrollTop(offset - 20);
                    }
                    SwapFactory.activateDroppables(scope.player.position.id);
                    if (scope.player.slot !== null && scope.player.slot < 12) {
                        SwapFactory.activateCaptainDroppable();
                    }
                }
            };

            scope.onDragStop = function() {
                SwapFactory.activateDroppables(null);
            };

            scope.draggableOptions = {
                animate: true,
                placeholder: 'keep',
                refreshPositions: true,
                onStart: 'onDragStart',
                onStop: 'onDragStop'
            };

            scope.dragOptions = {
                revert: 'invalid',
                helper: 'clone',
                appendTo: 'body',
                scroll: false,
                revertDuration: 300,
                opacity: 0.8,
                addClasses: false
            };

            scope.$on('$destroy', function() {
                element.off('click', onPlayerClick);
            });
        }
    };
});

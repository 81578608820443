'use strict';

/* @ngInject */
angular.module('bm.squad.lineup.bench').factory('SquadLineupBenchService',function () {

    var bench = [];

    /* Get all bench players
     * index - if defined, get by id
     * pop - if true, pops the element */
    function get(index, pop) {
        if (index === undefined) {
            return bench;
        } else {
            var found = bench[index];
            if (pop) {
                del(index);
            }
            return found;
        }
    }

    /* Delete all bench players
     * id - if defined, deletes by id */
    function del(index) {
        if (index === undefined) {
            bench = [];
        } else {
            bench.splice(index, 1);
        }
    }

    /* Add a player to bench players */
    function put(player, index) {
        var replacedPlayer = null;
        if (bench[index]) {
            replacedPlayer = bench[index];
        }
        bench[index] = player;
        return replacedPlayer;
    }

    return {
        get: get,
        del: del,
        put: put
    };
});
'use strict';

angular.module('bm.team-calculation-player').directive('bmTeamCalculationPlayerDetails', function () {
	return {
	    name: 'bmTeamCalculationPlayerDetails',
	    templateUrl: 'team-calculation-player-details-component.html',
		replace: true,
		scope: {
		    'player': '=',
            'isChallenge': '='
		},
		link: function (scope) {
		    var assistMultiplier = 1;
		    var goalMultiplier = 1;

		    if (!scope.isChallenge) {
		        scope.ratingPoints = scope.player.rating;
		        scope.bonus1name = 'TEAM_CALCULATION.PLAYER_DETAILS.' + scope.player.position + '_BONUS_1';
		        scope.bonus2name = 'TEAM_CALCULATION.PLAYER_DETAILS.' + scope.player.position + '_BONUS_2';
		        switch (scope.player.position) {
		            case 'T':
		                goalMultiplier = 4;
		                assistMultiplier = 3;
		                break;
		            case 'A':
		                goalMultiplier = 3;
		                assistMultiplier = 2;
		                break;
		            case 'M':
		                goalMultiplier = 2;
		                assistMultiplier = 1;
		                break;
		        }
		        scope.goalPoints = isNaN(parseInt(scope.player.goals)) ? scope.player.goals : goalMultiplier * (scope.player.goals - scope.player.goalPenalty) + scope.player.goalPenalty;
		        scope.assistPoints = isNaN(parseInt(scope.player.assist)) ? scope.player.assist : assistMultiplier * scope.player.assist;
		        scope.bonus1Points = scope.player.bonus1 ? 1 : '-';
		        scope.bonus2Points = scope.player.bonus2 ? 2 : '-';
		        switch (scope.player.card) {
		            case 'GK':
		                scope.cardName = 'TEAM_CALCULATION.PLAYER_DETAILS.YELLOW_CARD';
		                scope.cardPoints = -1;
		                break;
		            case 'GR':
		                scope.cardName = 'TEAM_CALCULATION.PLAYER_DETAILS.YELLOW_AND_RED_CARD';
		                scope.cardPoints = -2;
		                break;
		            case 'RK':
		                scope.cardName = 'TEAM_CALCULATION.PLAYER_DETAILS.RED_CARD';
		                scope.cardPoints = -3;
		                break;
		            default:
		                scope.cardName = 'TEAM_CALCULATION.PLAYER_DETAILS.NO_CARD';
		                scope.cardPoints = '-';
		        }
		    }
		}
	};
});

'use strict';

angular.module('dfs.tutorials').service('DfsTutorialsService', DfsTutorialsService);

/* @ngInject */
function DfsTutorialsService($q, $http, $state, bulimaConfig, UserService, ModalService) {

    function hadSeenTutorials(mode) {
        var tutorials = getSeenTutorials();

        if (!tutorials.DFS_HOME && !tutorials.MNG_HOME) {
            return openTutorialChooser();
        } else {
            if ((mode === 'dfs' && !tutorials.DFS_HOME) || (mode === 'mng' && !tutorials.MNG_HOME)) {
                return openTutorial(mode);
            } else {
                return $q.when();
            }
        }
    }

    function getSeenTutorials() {
        var tutorials = UserService.getSeenTutorials();
        var seenTutorials = {
            'DFS_HOME': false,
            'DFS_ALL': false,
            'MNG_HOME': false,
            'MNG_ALL': false,
            'ALL': false
        };

        for (var prop in seenTutorials) {
            if (tutorials.indexOf(prop) >= 0) {
                seenTutorials[prop] = true;
            }
        }

        return seenTutorials;
    }

    function setSeenTutorial(page) {
        if (page) {
            return $http.post(bulimaConfig.BASE_URL + '/writeaccountservice/finishedTutorial', page).then(function () {
                UserService.getManagerData();
            });
        }
    }
    function setSeenTutorialForMultipleMode(page1, page2) {
        if (page1 && page2) {
            return $http.post(bulimaConfig.BASE_URL + '/writeaccountservice/finishedTutorial', page1).then(function () {
                return $http.post(bulimaConfig.BASE_URL + '/writeaccountservice/finishedTutorial', page2).then(function () {
                    UserService.getManagerData();
                });
            });
        }
    }

    function openTutorialChooser() {
        return ModalService.openModalPopUp('dfsTutorialsStartModal.html', null, 'dfs-tutorials').then(function (mode) {
            if (!mode) {
                setSeenTutorialForMultipleMode('DFS_HOME', 'MNG_HOME');
                return;
            }
            return openTutorial(mode);
        }, function () {
            return false;
        });
    }

    function openTutorial(mode) {
        var manager = UserService.getManagerInfo();
        var params = {
            locale: manager.localization.abbreviation,
            mode: mode
        };
        return ModalService.openModalPopUp('dfsTutorialsModal.html', 'dfsTutorialsModalCtrl', 'dfs-tutorials', params).then(function () {
            var tutorials = getSeenTutorials();
            if (mode === 'dfs') {
                setSeenTutorial('DFS_HOME');
                setSeenTutorial('DFS_ALL');
                if (tutorials.MNG_ALL) {
                    setSeenTutorial('ALL');
                }
                $state.go('dfs.home');
            } else {
                setSeenTutorial('MNG_HOME');
                setSeenTutorial('MNG_ALL');
                if (tutorials.DFS_ALL) {
                    setSeenTutorial('ALL');
                }
                $state.go('app.home');
            }
        }, function (action) {
            if (action === 'skip') {
                if (mode === 'dfs') {
                    setSeenTutorial('DFS_HOME');
                    $state.go('dfs.home');
                } else {
                    setSeenTutorial('MNG_HOME');
                    $state.go('app.home');
                }
            }
            return false;
        });
    }

    this.hadSeenTutorials = hadSeenTutorials;
    this.openTutorial = openTutorial;
    this.openTutorialChooser = openTutorialChooser;
    this.setSeenTutorial = setSeenTutorial;
}

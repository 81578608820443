'use strict';

require('./bm.news.content/bm.news.content');
require('./bm.create.news/bm.create.news');

/* @ngInject */
angular.module('bm.news', [
    'bm.common',
    'bm.news.content',
    'bm.create.news'
]);

require('./NewsService');
require('./NewsComponentCtrl');

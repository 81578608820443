'use strict';

angular.module('dfs.tutorials').constant('dfsTutorialsConstant', [
    {
        imageUrl: {
            en: '/assets/images/dfs/tutorials/tutorial-dfs-page1-en.png',
            de: '/assets/images/dfs/tutorials/tutorial-dfs-page1-de.png',
            pl: '/assets/images/dfs/tutorials/tutorial-dfs-page1-en.png'
        },
        headline: 'DFS_TUTORIALS.PAGE1_HEADLINE',
        leftClass: ['dfs-col-14'],
        rightClass: ['dfs-col-10']
    },
    {
        imageUrl: '/assets/images/dfs/tutorials/tutorial-dfs-page2.png',
        headline: 'DFS_TUTORIALS.PAGE2_HEADLINE',
        leftClass: ['dfs-col-sm-15', 'dfs-col-13'],
        rightClass: ['dfs-col-sm-8', 'dfs-col-offset-1', 'dfs-col-10']
    },
    {
        imageUrl: '/assets/images/dfs/tutorials/tutorial-dfs-page3.png',
        headline: 'DFS_TUTORIALS.PAGE3_HEADLINE',
        leftClass: ['dfs-col-sm-12', 'dfs-col-16'],
        rightClass: ['dfs-col-sm-8', 'dfs-col-offset-sm-4', 'dfs-col-8']
    },
    {
        imageUrl: {
            en: '/assets/images/dfs/tutorials/tutorial-dfs-page4.png',
            de: '/assets/images/dfs/tutorials/tutorial-dfs-page4-de.png',
            pl: '/assets/images/dfs/tutorials/tutorial-dfs-page4.png'
        },
        headline: 'DFS_TUTORIALS.PAGE4_HEADLINE',
        description: 'DFS_TUTORIALS.PAGE4_DESCRIPTION',
        leftClass: ['dfs-col-16'],
        rightClass: ['dfs-col-8']
    },
    {
        imageUrl: '/assets/images/dfs/tutorials/tutorial-dfs-page5.png',
        headline: 'DFS_TUTORIALS.PAGE5_HEADLINE',
        leftClass: ['dfs-col-md-12', 'dfs-col-14'],
        rightClass: ['dfs-col-md-12', 'dfs-col-10']
    },
    {
        imageUrl: '/assets/images/dfs/tutorials/tutorial-dfs-page6.png',
        headline: 'DFS_TUTORIALS.PAGE6_HEADLINE',
        leftClass: ['dfs-col-md-12', 'dfs-col-14'],
        rightClass: ['dfs-col-md-12', 'dfs-col-10']
    }
]);

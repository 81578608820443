(function (angular) {
    'use strict';

    /* @ngInject */
    angular.module('bm.assets-slider', []).directive('bmAssetsSlider', function () {
        return {
            name: 'bmAssetsSlider',
            replace: true,
            scope: {
                assets: '=bmAssetsSlider',
                selected: '=',
                clickHandler: '&onSelect',
                titleproperty: '@',
                urlproperty: '@',
                elements: '=',
                assetwidth: '=',
                sizeunit: '@',
                assetmargin: '=',
                nullvalue: '=',
                nulltext: '@'
            },
            restrict: 'AE',
            templateUrl: 'assets-slider.html',
            link: function (scope) {

                var elementsInRow = scope.elements || 5;
                var sizeUnit = scope.sizeunit || 'px';
                var assetWidth = scope.assetwidth || 60;
                var middleElement = Math.ceil(elementsInRow / 2);
                var margin = isNaN(parseInt(scope.assetmargin)) ? 5 : parseInt(scope.assetmargin);

                scope.sliderPosition = 0;
                scope.elementsInRow = elementsInRow;

                scope.assetMargin = '0 ' + margin + sizeUnit;
                scope.nullAssetMargin = 2 * margin + sizeUnit;

                scope.assetSize = assetWidth - (2 * margin) + sizeUnit;
                scope.spanLineHeight = assetWidth - (2 * margin) - 16 + sizeUnit;
                scope.maxWidth = (elementsInRow * assetWidth) + sizeUnit;
                scope.arrowMargin = Math.floor((assetWidth - 15 - (2 * margin)) / 2) + sizeUnit;

                scope.arrowLeftIcon = 'icon-custom-chevron-left';
                scope.arrowRightIcon = 'icon-custom-chevron-right';

                scope.urlproperty = scope.urlproperty || 'url';
                scope.slideWidth = '0';

                scope.$watch('selected', function (newV, oldV) {
                    if (newV !== oldV && newV !== null && newV !== undefined) {
                        slideTo(newV);
                    }
                });

                scope.slideLeft = function () {
                    var newPosition = scope.sliderPosition > elementsInRow ? scope.sliderPosition - elementsInRow : 0;

                    scope.slideWidth = -newPosition * assetWidth + sizeUnit;
                    scope.sliderPosition = newPosition;
                };

                scope.slideRight = function () {
                    var newPosition = scope.sliderPosition + elementsInRow;
                    if (newPosition > scope.assets.length - elementsInRow) {
                        newPosition = scope.assets.length - elementsInRow;
                    }
                    scope.slideWidth = -newPosition * assetWidth + sizeUnit;
                    scope.sliderPosition = newPosition;
                };

                scope.slideLeftToElement = function (numberOfSlots) {
                    var newPosition = scope.sliderPosition - numberOfSlots;
                    if (newPosition < 0) {
                        return;
                    }

                    selectAsset(newPosition);

                    if (elementsInRow < scope.assets.length) {
                        var floored = Math.floor(elementsInRow / 2);
                        if (newPosition + middleElement < scope.assets.length && newPosition >= floored) {
                            var moveSlots = newPosition - floored;
                            scope.sliderPosition = moveSlots;
                            scope.slideWidth = -moveSlots * assetWidth + sizeUnit;
                        } else if (newPosition - middleElement <= 0) {
                            scope.slideWidth = '0';
                            scope.sliderPosition = 0;
                        }
                    }
                };

                scope.slideRightToElement = function (numberOfSlots) {
                    var newPosition = scope.sliderPosition + numberOfSlots;
                    if (newPosition >= scope.assets.length) {
                        return;
                    }

                    selectAsset(newPosition);

                    if (elementsInRow < scope.assets.length) {
                        var floored = Math.floor(elementsInRow / 2);
                        var moveSlots;
                        if (newPosition + floored < scope.assets.length && newPosition > floored) {
                            moveSlots = newPosition - floored;
                            scope.slideWidth = -moveSlots * assetWidth + sizeUnit;
                            scope.sliderPosition = moveSlots;
                        } else if (newPosition + floored >= scope.assets.length) {
                            moveSlots = scope.assets.length - elementsInRow;
                            scope.slideWidth = -moveSlots * assetWidth + sizeUnit;
                            scope.sliderPosition = moveSlots;
                        }
                    }
                };

                function slideTo(position) {
                    if (position <= scope.sliderPosition) {
                        scope.slideLeftToElement(scope.sliderPosition - position);
                    } else {
                        scope.slideRightToElement(position - scope.sliderPosition);
                    }
                }

                function selectAsset(position) {
                    scope.selected = position;

                    scope.clickHandler({
                        'asset': scope.selected
                    });
                }

                scope.onClick = function (index) {
                    selectAsset(index);
                };
            }
        };
    });

})(angular);

'use strict';

require('../bm.common/bm.moment');

/* @ngInject */
angular.module('bm.localization').provider('LocalizationService', function($moment) {

    var initiated = false;
    var languages = {
        DE: 'de',
        EN: 'en',
        PL: 'pl'
    };
    var languageKey;
    var browserLanguage;
    var currentLanguage;

    this.setLanguageKey = setLanguageKey;
    this.getLanguageKey = getLanguageKey;
    this.getLanguage = getLanguage;
    this.languages = languages;

    function setLanguageKey(lang) {
        languageKey = browserLanguage = lang;
    }

    function getLanguageKey() {
        return languageKey;
    }

    function getLanguage() {
        return currentLanguage;
    }

    function formatAsArray(langs) {
        var temp = [];
        for (var i in langs) {
            if (langs[i].id) {
                temp.push(langs[i]);
            }
        }
        return temp;
    }

    this.$get = ['bulimaConfig', '$q', '$http', '$translate', function(bulimaConfig, $q, $http, $translate) {

        function getLanguages() {
            if (initiated) {
                return $q.when(formatAsArray(languages));
            }
            return $http.post(bulimaConfig.BASE_URL + '/commonservice/getAllLocalizations')
                .then(function(response) {
                    var data = response.data;
                    for (var i = 0; i < data.length; i++) {
                        var lang = data[i];
                        languages[lang.abbreviation] = lang;
                        languages[lang.abbreviation.toUpperCase()] = lang.abbreviation;
                    }
                    currentLanguage = languages[languageKey];
                    initiated = true;
                    return formatAsArray(languages);
                });
        }

        function saveLanguage(language) {
            return $http.post(bulimaConfig.BASE_URL + '/writemanagerservice/saveUserManagerLocalization', language.id)
                .then(function () {
                    setLanguage(language);
                });
        }

        function setLanguage(newLanguage) {
            var lang = newLanguage.abbreviation;
            setLanguageKey(lang);
            currentLanguage = languages[languageKey];
            $translate.use(languageKey);
            $moment.locale(languageKey);
            return true;
        }

        function reset() {
            languageKey = browserLanguage;
            currentLanguage = languages[languageKey];
            $translate.use(languageKey);
        }

        return {
            setLanguage: setLanguage,
            saveLanguage: saveLanguage,
            getLanguage: getLanguage,
            getLanguages: getLanguages,
            getLanguageKey: getLanguageKey,
            languages: languages,
            reset: reset
        };
    }];

});

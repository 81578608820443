'use strict';


/* @ngInject */
function bmSquadStatisticsDuelsTabsCtrl() {



}

angular.module('bm.squad.statistics.tabs.duels').controller('bmSquadStatisticsDuelsTabsCtrl', bmSquadStatisticsDuelsTabsCtrl);

'use strict';

angular.module('bm.squad.statistics.tabs.duels', []).directive('bmSquadStatisticsTabsDuels', function () {
    return {
        name: 'bmSquadStatisticsTabsDuels',
        templateUrl: 'bmSquadStatisticsDuelsTabs.html',
        controller: 'bmSquadStatisticsDuelsTabsCtrl',
        replace: true,
        scope: {
            getPremium: '&',
            showGraphClick : '&',
            datacolumnsDuels : '=datacolumns',
            datapointsDuels : '=datapoints',
            showData : '&',
            onrendered :'&onrendered',
            isPremium:'=',
            chartInformation:'=chartInformation'
        }
    };
});

require('./bmSquadStatisticsDuelsTabsCtrl');


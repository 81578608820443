'use strict';
angular.module('bm.header').controller('dfsHeaderCtrl', dfsHeaderCtrl);

/* @ngInject */
function dfsHeaderCtrl($scope, $state, $window, UserService, ClubService, SwitchClubModalService) {

    $scope.activeHeaderLeft = $scope.activeHeader === 'left';

    $scope.club = ClubService.myClub;

    $scope.openSwitchClubModal = SwitchClubModalService.openSwitchClubModal;

    $scope.ui = {
        scrollTop: 0,
        collapseBottom: false
    };

    $scope.collapseBottom = function (flag) {
        if ($scope.ui.scrollTop > 60) {
            $scope.ui.collapseBottom = flag;
        }
    };

    $scope.goPrevState = function () {
        $window.history.back();
    };

    $scope.goToProfilePage = function () {
        if ($scope.activeHeaderLeft) {
            $state.go('dfs.profile.overview');
        } else {
            $state.go('app.profile.overview');
        }
    };

    $scope.goToShopCoins = function () {
        if ($scope.activeHeaderLeft) {
            $state.go('dfs.shop.coins');
        } else {
            $state.go('app.shop.coins');
        }
    };

    $scope.goToShopRewards = function () {
        if ($scope.activeHeaderLeft) {
            $state.go('dfs.shop.rewards');
        } else {
            $state.go('app.shop.rewards');
        }
    };

    $scope.$watch(function () {
        return UserService.myData;
    }, function (newV, oldV) {
        if (!$scope.manager || newV !== oldV) {
            if (newV) {
                $scope.manager = newV;
                $scope.avatarUrl = $scope.manager.user && $scope.manager.user.avatar && $scope.manager.user.avatar.photoUrl ? $scope.manager.user.avatar.photoUrl : 'assets/images/dfs/no-profile.svg';
                $scope.levelProgress = $scope.manager.experienceLevelPoints === 0 ? 0 : $scope.manager.experienceLevelPoints / $scope.manager.experienceLevel.experiencePointsNeeded;
            }
        }
    });

    angular.element($window).bind('scroll', function () {
        $scope.ui.scrollTop = $(this).scrollTop();
        $scope.$apply();
    });

    $scope.logout = function() {
        UserService.logout();
    };
}

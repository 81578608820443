'use strict';

angular.module('bulima.shared').factory('BugReportService', BugReportService);

var appVersion = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName = navigator.appName;
var fullVersion = '' + parseFloat(appVersion);
var majorVersion = parseInt(appVersion, 10);
var nameOffset, verOffset, ix;

// In Opera, the true version is after 'Opera' or after 'Version'
if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
    }
}
// In MSIE, the true version is after 'MSIE' in userAgent
else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
}
// In Chrome, the true version is after 'Chrome' 
else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
}
// In Safari, the true version is after 'Safari' or after 'Version' 
else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
    }
}
// In Firefox, the true version is after 'Firefox' 
else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
}
// In most other browsers, 'name/version' is at the end of userAgent 
else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
    }
}
// trim the fullVersion string at semicolon/space if present
if ((ix = fullVersion.indexOf(';')) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
}
if ((ix = fullVersion.indexOf(' ')) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
}

majorVersion = parseInt('' + fullVersion, 10);
if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(appVersion);
    majorVersion = parseInt(appVersion, 10);
}

var OSName;
var OSMap = {
    'Linux': 'Linux',
    'Windows NT 5.1': 'Windows XP x32',
    'Windows NT 5.2': 'Windows XP x64',
    'Windows NT 6': 'Windows Vista',
    'Windows NT 6.1': 'Windows 7',
    'Windows NT 6.2': 'Windows 8',
    'Windows NT 6.3': 'Windows 8.1',
    'Windows NT 10': 'Windows 10'
};

var start = nAgt.indexOf('Android');
if (start !== -1) {
    OSName = nAgt.slice(start, nAgt.indexOf(';', start));
} else {
    start = nAgt.indexOf('(');
    var end = nAgt.indexOf(';', start);
    var OSKey = nAgt.slice(start + 1, end);
    OSName = OSMap[OSKey];
}

/* @ngInject */
function BugReportService($http, bulimaConfig, Notifier) {
    var bugReport = {};

    bugReport.sendReport = function(report) {
        report.deviceType = browserName + ' ' + fullVersion;
        report.system = OSName || 'Unknown OS';
        return $http.post(bulimaConfig.BASE_URL + '/commonservice/reportbug', report)
            .then(function() {
                //Notifier.success('BUG_REPORT.SEND_SUCCESS');
            }, function() {
                Notifier.error('BUG_REPORT.SEND_ERROR');
            });
    };

    return bugReport;
}



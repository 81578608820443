'use strict';

angular.module('dfs.match-results', []).directive('dfsMatchResults', function () {
    return {
        name: 'dfsMatchResults',
        templateUrl: 'dfsMatchResults.html',
        replace: true,
        restrict: 'AE',
        scope: {
            'matches' : '='
        }
    };
});
'use strict';

/* @ngInject */
angular.module('dfs.side-news-item', []).directive('dfsSideNewsItem', function ($moment) {
    return {
        name: 'dfsSideNewsItem',
        templateUrl: 'dfsSideNewsItem.html',
        replace: true,
        scope: {
            newsText: '=',
            newsTime: '=',
            maxChars: '@'
        },
        link: function (scope) {
            if (scope.newsTime) {
                scope.time = $moment(parseInt(scope.newsTime)).format('HH:mm');
                scope.date = $moment(parseInt(scope.newsTime)).format('DD.MM.');
            }
            if (scope.maxChars) {
                var maxLength = parseInt(scope.maxChars);
                if (scope.newsText.length > maxLength) {
                    var shortText = scope.newsText.slice(0, maxLength);
                    var regex1 = /([\s\S]*)(<.*)$/g;
                    var regex2 = /([\s\S]*\w)(\W+)/g;
                    if (shortText.match(regex1) !== null) {
                        shortText = regex1.exec(shortText)[1];
                    }
                    scope.shortNewsText = regex2.exec(shortText)[1] + '...';
                }
            }
        }
    };
});
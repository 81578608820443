(function(angular) {
    'use strict';

    /* @ngInject */
    function SquadSaveModalCtrl($scope, params, SessionService) {

        $scope.params = params;
        var lineUpFlag = params.storageId;

        var data = SessionService.getLineUpFlag(lineUpFlag) || {};

        $scope.flag = {
            name: params.flagName,
            value: data[params.flagName] || false
        };

        /**
         * This method is called on checkbox change. It create or set object in storage
         * in true(if flag is check in), or false (if it's check out.)
         */
        $scope.popupFlagChange = function() {

            data[params.flagName] = $scope.flag.value;
            // if ($scope.flag.value) { //add flag to user storage and set to true
            // if (data && data[params.flagName]) {
            //     data[params.flagName] = true;
            // } else {
            // data[params.flagName] = true;
            // }
            // } else { //if exist flag in storage set him to false
            //     data[params.flagName] = false;
            // }
            SessionService.setLineUpFlag(lineUpFlag, data);
        };

        // $scope.save = function() {
        //     SquadService.saveLineup(params.formationData)
        //         .then(function(){
        //             ClubService.getClub();
        //         });
        //     $modalInstance.dismiss('cancel');
        // };

    }

    angular.module('bm.squad').controller('SquadSaveModalCtrl', SquadSaveModalCtrl);

})(angular);

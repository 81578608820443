'use strict';

angular.module('bm.league-selector', []).directive('bmLeagueSelector', function () {
	return {
	    name: 'bmLeagueSelector',
		templateUrl: 'league-selector-component.html',
		replace: true,
		scope: {
			'leagues': '=',
			'joinLeague': '&',
            'pagination': '='
		},
        controller: 'LeagueSelectorCtrl'
	};
});

require('./LeagueSelectorCtrl');

'use strict';

angular.module('dfs.tutorials').constant('mngTutorialsConstant', [
    {
        imageUrl: '/assets/images/dfs/tutorials/MM_Tutorial_1.jpg',
        headline: 'MNG_TUTORIALS.PAGE1_HEADLINE',
        description: 'MNG_TUTORIALS.PAGE1_DESCRIPTION',
        leftClass: ['dfs-col-24', 'dfs-textCenter'],
        rightClass: ['dfs-col-sm-12', 'dfs-col-offset-sm-6', 'dfs-col-24', 'dfs-push-top-2', 'dfs-textCenter']
    },
    {
        imageUrl: {
            en: '/assets/images/dfs/tutorials/MM_Tutorial_2-en.png',
            de: '/assets/images/dfs/tutorials/MM_Tutorial_2-de.png',
            pl: '/assets/images/dfs/tutorials/MM_Tutorial_2-en.png'
        },
        headline: 'MNG_TUTORIALS.PAGE2_HEADLINE',
        leftClass: ['dfs-col-sm-10', 'dfs-col-24'],
        rightClass: ['dfs-col-sm-13', 'dfs-col-offset-sm-1', 'dfs-col-24', 'dfs-push-top-2-xs', 'dfs-textCenter']
    },
    {
        imageUrl: '/assets/images/dfs/tutorials/MM_Tutorial_3.png',
        headline: 'MNG_TUTORIALS.PAGE3_HEADLINE',
        leftClass: ['dfs-col-sm-12', 'dfs-col-16'],
        rightClass: ['dfs-col-sm-8', 'dfs-col-offset-sm-4', 'dfs-col-8']
    },
    {
        imageUrl: {
            en: '/assets/images/dfs/tutorials/MM_Tutorial_4-en.png',
            de: '/assets/images/dfs/tutorials/MM_Tutorial_4-de.png',
            pl: '/assets/images/dfs/tutorials/MM_Tutorial_4-en.png'
        },
        headline: 'MNG_TUTORIALS.PAGE4_HEADLINE',
        leftClass: ['dfs-col-sm-12', 'dfs-col-24'],
        rightClass: ['dfs-col-sm-8', 'dfs-col-offset-sm-4', 'dfs-col-24', 'dfs-push-top-2-xs', 'dfs-textCenter']
    },
    {
        imageUrl: {
            en: '/assets/images/dfs/tutorials/MM_Tutorial_5-en.png',
            de: '/assets/images/dfs/tutorials/MM_Tutorial_5-de.png',
            pl: '/assets/images/dfs/tutorials/MM_Tutorial_5-en.png'
        },
        headline: 'MNG_TUTORIALS.PAGE5_HEADLINE',
        description: 'MNG_TUTORIALS.PAGE5_DESCRIPTION',
        leftClass: ['dfs-col-24', 'dfs-textCenter'],
        rightClass: ['dfs-col-sm-16', 'dfs-col-offset-sm-4', 'dfs-col-24', 'dfs-push-top-2', 'dfs-textCenter']
    }
    //{
    //    imageUrl: '/assets/images/dfs/tutorials/MM_Tutorial_6.jpg',
    //    headline: 'MNG_TUTORIALS.PAGE6_HEADLINE',
    //    leftClass: ['dfs-col-md-12', 'dfs-col-sm-13', 'dfs-col-24'],
    //    rightClass: ['dfs-col-md-10', 'dfs-col-offset-md-2', 'dfs-col-offset-sm-1', 'dfs-col-24', 'dfs-push-top-2', 'dfs-textCenter', 'dfs-tutorial-img-shadow']
    //}
]);

'use strict';

/* @ngInject */
angular.module('localytics').service('LocalyticsEventTracker', function ($analytics, bulimaConfig) {
    
    this.eventTrack = eventTrack;
        
    function eventTrack(eventName, trackingData) {
        if (bulimaConfig.INTERNATIONAL) {
            $analytics.eventTrack(eventName, trackingData);
        }
    }

});

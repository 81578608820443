'use strict';

angular.module('bm.stats-item', []).directive('bmStatsItem', function () {
	return {
	    name: 'bmStatsItem',
	    templateUrl: 'stats-item-component.html',
		replace: true,
		scope: {
		    'item': '=',
		    'detailsProp': '=',
            'rankStyle': '@',
		    'itemRank': '@',
		    'itemImg': '@',
		    'itemName': '@',
            'itemClub': '@',
            'itemPoints': '@',
            'pointsFilter': '@'
		},
		link: function (scope) {
		    scope.hasRank = scope.itemRank !== undefined;
		    scope.hasDetails = scope.detailsProp !== undefined;

		    if (scope.itemImg) {
		        scope.imageUrl = scope.itemImg || 'assets/images/player/noplayer.svg';
		    }

		    scope.showDetails = false;
		    scope.toggleDetails = function () {
		        if (scope.hasDetails) {
		            scope.showDetails = !scope.showDetails;
		        }
		    };
		}
	};
});

require('./bm.stats-item.points/bm.stats-item.points');
require('./bm.stats-item.details/bm.stats-item.details');
'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsHexagon', function () {
    return {
        name: 'dfsHexagon',
        templateUrl: 'dfsHexagon.html',
        replace: true,
        transclude: true,
        scope: {
            hexagonSize: '@',
            hexagonWidth: '@',
            hexagonColor: '@'
        },
        link: function (scope) {
            scope.hexHeight = scope.hexagonSize + 'px';
            scope.hexBorderHeight = scope.hexagonSize * 0.5 + 'px';
            scope.hexBorderWidth = scope.hexagonSize * 0.3 + 'px';
            if (!scope.hexagonWidth) {
                scope.hexagonWidth = scope.hexagonSize * 0.6 + 'px';
            }
        }
    };
});
'use strict';

angular.module('bm.selectlist', []).directive('bmSelectlist', function () {
	return {
		name: 'bmSelectlist',
		templateUrl: 'selectlist-component.html',
		replace: true,
		scope: {
			'ngModel': '=',
			'options': '=selectlistOptions',
            'onChange': '&'
		},
		link: function (scope) {
			scope.selectOption = function (value) {
			    scope.ngModel = value;
			    scope.onChange({ 'value': value });
			};
		}
	};
});

'use strict';

/* @ngInject */
angular.module('bm.staticHeader').directive('bmStaticHeader', function () {
    return {
        restrict: 'EA',
        templateUrl: 'static-header.html',
        // controller: 'StaticHeaderCtrl',
        link: function () {
            
        }
    };
});

'use strict';

angular.module('bm.squad.statistics.tabs.points', []).directive('bmSquadStatisticsTabsPoints', function () {
    return {
        name: 'bmSquadStatisticsTabsPoints',
        templateUrl: 'bmSquadStatisticsPointsTabs.html',
        controller: 'bmSquadStatisticsPointsTabsCtrl',
        replace: true,
        scope: {
            getPremium: '&',
            showGraphClick : '&',
            datacolumnsPoints : '=datacolumns',
            datapointsPoints : '=datapoints',
            showData : '&',
            onrendered :'&onrendered',
            isPremium:'=',
            chartInformation:'=chartInformation'
        }
    };
});

require('./bmSquadStatisticsPointsTabsCtrl');


//require('./twitter/twitter');
require('./facebook/facebook');
require('./google/google');
require('./localytics/localytics');
require('./bm.common/bm.common');
require('./bm.assets-slider/bm.assets-slider');
require('./bm.bug-report/bm.bug-report');
require('./bm.footer/bm.footer');
require('./bm.header/bm.header');
require('./bm.dropdown/bm.dropdown');
require('./bm.share-modal/bm.share-modal');
require('./bm.slider/SliderService');
require('./bm.tutorials/bm.tutorials');
require('./bm.analytics/bm.analytics');
require('./bm.scrollWrapper/bm.scrollWrapper');
require('./zeroclipboard/zeroclipboard');
require('./bm.news/bm.news');
require('./bm.squad/bm.squad');
require('./bm.tab-focus/bm.tab-focus');
require('./bm.localization/bm.localization');
//require('./bm.ivw-pixel/bm.ivw-pixel');
require('./bm.googletag/bm.googletag');
require('./bm.remaining-time/bm.remaining-time');
require('./bm.selectlist/bm.selectlist');
require('./bm.teamrank/bm.teamrank');
require('./bm.rankchange/bm.rankchange');
require('./bm.popmenu/bm.popmenu');
require('./bm.shop-article/bm.shop-article');
require('./bm.progress/bm.progress');
require('./bm.league-selector/bm.league-selector');
require('./bm.team-calculation-player/bm.team-calculation-player');
require('./bm.matchday-challenge/bm.matchday-challenge');
require('./bm.squad-positions/bm.squad-positions');
require('./bm.stats-item/bm.stats-item');

require('./dfs.common/dfs.common');
require('./dfs.header/dfs.header');
require('./dfs.footer/dfs.footer');
require('./dfs.tutorials/dfs.tutorials');
require('./dfs.match-results/dfs.match-results');
require('./dfs.shop-promo/dfs.shop-promo');
require('./dfs.side-news-item/dfs.side-news-item');

require('./kicker.footer/kicker.footer');

require('./bootstrap');

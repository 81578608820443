'use strict';

require('./google');

angular.module('google')
    .service('ShortUrlService', function($http) {

        this.shorten = shortenFn;

        var apiPath = 'https://www.googleapis.com/';

        function shortenFn(url) {
            return $http.post(apiPath + 'urlshortener/v1/url?key=' + 'AIzaSyBIdPok3xJHUtEUxJdWzcLI743gu8zv4gw', {
                longUrl: url
            }, {
                headers: {
                    'Authorization': undefined
                }
            }).then(function(response) {
                return response.data.id;
            }, function() {
                return url;
            });
        }

    });

'use strict';

angular.module('google')
    .provider('ga', function() {

        var gaGlobal = 'ga';

        this.init = init;

        function init(appKey) {
            window[gaGlobal]('create', appKey);
        }

        (function(w, d, gaGlobal) {
            var js,
                id = 'ga-jssdk',
                ref = d.getElementsByTagName('script')[0];

            if (d.getElementById(id)) {
                return;
            }

            js = d.createElement('script');
            w.GoogleAnalyticsObject = gaGlobal;
            w[gaGlobal] = w[gaGlobal] || function() {
                (w[gaGlobal].q = w[gaGlobal].q || []).push(arguments);
            };
            w[gaGlobal].l = Date.now();
            js.id = id;
            js.src = '//www.google-analytics.com/analytics.js';
            ref.parentNode.insertBefore(js, ref);
        })(window, document, gaGlobal);

        this.$get = [function() {
            return window.ga;
        }];
    });

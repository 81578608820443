'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsMaxChars', function () {
    return {
        name: 'dfsMaxChars',
        templateUrl: 'dfsMaxChars.html',
        scope: {
            maxChars: '@dfsMaxChars',
            text: '=dfsMaxCharsBind'
        },
        link: function (scope) {
            if (scope.maxChars) {
                var maxLength = parseInt(scope.maxChars);
                if (scope.text.length > maxLength) {
                    var shortText = scope.text.slice(0, maxLength);
                    var regex1 = /([\s\S]*)(<.*)$/g;
                    var regex2 = /([\s\S]*\w)(\W+)/g;
                    if (shortText.match(regex1) !== null) {
                        shortText = regex1.exec(shortText)[1];
                    }
                    scope.shortText = regex2.exec(shortText)[1] + '...';
                }
            }
        }
    };
});
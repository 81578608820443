'use strict';

require('./bm.fixedHeader/bm.fixedHeader');
require('./bm.staticHeader/bm.staticHeader');
require('./bm.mobileHeader/bm.mobileHeader');

angular.module('bm.header', [
    'bm.fixedHeader',
    'bm.staticHeader',
    'bm.mobileHeader'
]).directive('bmHeader', function () {
    return {
        name: 'bmHeader',
        templateUrl: 'header.html',
        controller: 'HeaderCtrl',
        replace: true
    };
});

require('./HeaderCtrl');

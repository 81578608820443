'use strict';

/* @ngInject */
angular.module('bm.fixedHeader').directive('bmFixedHeader', function () {
    return {
        restrict: 'EA',
        templateUrl: 'fixed-header.html',
        // controller: 'FixedHeaderCtrl',
        link: function () {
            
        }
    };
});

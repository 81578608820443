'use strict';

angular.module('bm.matchday-challenge', []).directive('bmMatchdayChallenge', function () {
	return {
	    name: 'bmMatchdayChallenge',
		templateUrl: 'matchday-challenge-component.html',
		replace: true,
		scope: {
		    'challenge': '=bmMatchdayChallenge',
		    'startChallenge': '&',
		    'challengeResults': '&',
            'isClickable': '=',
		    'isFinished': '=',
		    'showDetails': '=',
            'showResults': '='
		}
	};
});
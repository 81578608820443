'use strict';

require('../bm.bug-report/bm.bug-report');

/* @ngInject */
angular.module('bm.footer', ['bm.bug-report']);

require('./FooterCtrl');
require('./bmFooterPosition');


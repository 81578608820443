'use strict';

angular.module('bm.squad.statistics.tabs.shots', []).directive('bmSquadStatisticsTabsShots', function () {
    return {
        name: 'bmSquadStatisticsTabsShots',
        templateUrl: 'bmSquadStatisticsShotsTabs.html',
        controller: 'bmSquadStatisticsShotsTabsCtrl',
        replace: true,
        scope: {
            getPremium: '&',
            showGraphClick : '&',
            datacolumnsShots : '=datacolumns',
            datapointsShots : '=datapoints',
            showData : '&',
            onrendered :'&onrendered',
            isPremium:'=',
            chartInformation:'=chartInformation'
        }
    };
});

require('./bmSquadStatisticsShotsTabsCtrl');


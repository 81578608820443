'use strict';

angular.module('bm.dropdown', []).directive('bmDropdown', function($timeout) {

    function postLink(scope, element, attrs, ctrl) {

        var property = scope.property = attrs.property;
        var dontReverse = scope.$eval(attrs.dontReverse);
        var placeholder = attrs.placeholder;

        scope.titleProperty = attrs.titleProperty;

        scope.isPlaceholder = true;

        scope.propTranslate = attrs.propTranslate ? scope.$eval(attrs.propTranslate) : true;

        scope.$watch(attrs.list, function(newList) {
            scope.list = newList;
            if (newList) {
                if (newList[0].placeholder) {
                    placeholder = newList[0][property];
                }
            }
        });

//        scope.$watch(attrs.open, function(newV) {
//            if (newV && newV !== visible) {
//                toggleVisible();
//            }
//        });

//        function toggleVisible() {
//            if (visible) {
//                close();
//            } else {
//                scope.show();
//            }
//        }

        var $document = angular.element(document);

        var visible = false;

        function close() {
            element.removeClass('show');
            $document.off('click', closeOnDocCLick);
            element.off('click', onClickElement);
            visible = false;
        }

        function closeOnDocCLick() {
            if (visible) {
                close();
            }
        }

        function onClickElement(event) {
            event.preventDefault();
            event.stopPropagation();
            if (visible) {
                close();
            }
        }

        var order = 0;

        scope.select = function(item) {
            if (item.placeholder) {
                ctrl.$setViewValue({});
            } else if ((item !== scope.selected) && (item.disabled === undefined || !item.disabled)) {
                if (!dontReverse) {
                    item.order = order = 0;
                }
                ctrl.$setViewValue(angular.copy(item));
            }
            ctrl.$render();
            close();
        };

        scope.isSelected = function(item) {
            return item[property] === scope.selected[property];
        };

        scope.reverse = function(event) {
            if (dontReverse) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            order = ++order % 2;
            scope.selected.order = order;
            ctrl.$setViewValue(angular.copy(scope.selected), event);
        };

        scope.show = function() {
            if (!visible) {
                $timeout(function() {
                    //na element zakaciti event koji radi event default i stopPropagination
                    element.on('click', onClickElement);
                    //zakaciti event listener na document da radi zatvaranje
                    $document.on('click', closeOnDocCLick);
                });
                element.addClass('show');
                visible = true;
            }
        };

        ctrl.$render = function() {
            if (angular.equals({}, ctrl.$viewValue) || ctrl.$isEmpty(ctrl.$viewValue)) {
                scope.selected = {};
                scope.selected[property] = placeholder || '';
            } else {
                scope.selected = ctrl.$viewValue;
                scope.isPlaceholder = false;
            }
        };
    }

    return {
        name: 'bmDropdown',
        restrict: 'EA',
        replace: true,
        templateUrl: 'dropdown.html',
        require: 'ngModel',
        scope: true,
        link: postLink
    };
});

'use strict';

require('./CommentModel');

/* @ngInject */
angular.module('bm.news').factory('NewsModel', function(BaseClass, CommentModel, bulimaConfig, $http) {

    NewsModel.inherits(BaseClass);

    function getTypeClass(type) {
        var iconClass;
        switch (type) {
            case 'lineup':
                iconClass = 'icon-news-lineup';
                break;
            case 'stryking':
                iconClass = 'icon-news-stryking';
                break;
            case 'matchday':
                iconClass = 'icon-news-matchday';
                break;
            case 'transfer':
                iconClass = 'icon-news-transfer';
                break;
            case 'welcome':
                iconClass = 'icon-news-welcome';
                break;
            case 'scout':
                iconClass = 'icon-news-scout';
                break;
            case 'leaguejoined':
                iconClass = 'icon-news-leaguejoin';
                break;
            case 'clubleft':
                iconClass = 'icon-news-clubleft';
                break;
            case 'leaguenamechanged':
                iconClass = 'icon-news-namechange';
                break;
            case 'support':
                iconClass = 'icon-news-support';
                break;
            case 'reward':
                iconClass = 'icon-news-reward';
                break;
            case 'promo':
                iconClass = 'icon-news-promo';
                break;
            case 'challenge':
                iconClass = 'icon-news-challenge';
                break;
        }
        return iconClass;
    }

    function NewsModel(news) {
        if (!news) {
            this.type = 'official';
            this.typeClass = 'icon-news-custom';
            this.text = '';
            this.heading = '';
            return;
        }

        this.id = news.id;
        this.setType(news.newsType);
        this.category = news.newsCategory;
        this.heading = news.headline;
        this.text = news.newsMessage;
        this.date = news.date;
        this.commentsCount = news.commentsCount || 0;
        this.creator = news.club;

        if (this.type === 'transfer') {
            this.player = news.newsPlayer;
        }

        if (this.type === 'private') {
            this.receivers = news.idclubReciver;
        }
    }

    NewsModel.prototype.setType = function(type) {
        if (type === 'official' || type === 'private') {
            this.type = 'custom';
        } else {
            this.type = type.toLowerCase();
        }
        this.typeClass = getTypeClass(this.type);
    };

    NewsModel.prototype.setCreator = function(club) {
        this.creator = club;
    };

    NewsModel.prototype.serialize = function() {
        var news = {
            headline: this.heading,
            newsMessage: this.text,
            newsType: this.type
        };
        if (this.receivers) {
            for (var index in this.receivers) {
                news.idclubReciver = this.receivers[index].id; // TODO: Support multiple receivers for later use.
            }
        }
        return news;
    };

    NewsModel.prototype.setReceivers = function(receivers) {
        this.receivers = receivers;
    };

    NewsModel.prototype.getReceivers = function() {
        return this.receivers;
    };

    NewsModel.prototype.removeReceiver = function(receiver) {
        if (!receiver) {
            this.receivers = {};
            return 0;
        }
        delete this.receivers[receiver.id];
        return Object.keys(this.receivers).length;
    };

    NewsModel.prototype.getComments = function() {
        var self = this;
        return $http.post(bulimaConfig.BASE_URL + '/news/getComments/' + this.id)
            .then(function(response) {
                    self.comments = [];
                    if (response.status === 200) {
                        var comments = response.data;
                        for (var i = 0; i < comments.length; i++) {
                            self.comments.push(CommentModel.new(comments[i]));
                        }
                    }
                    self.comments.limit = 3;
                    return self.comments;
                },
                function(error) {
                    if (error.status === 400) {
                        self.deleted = true;
                    }
                });
    };

    NewsModel.prototype.addComment = function(newComment, forClub) {
        var self = this;
        return $http.post(bulimaConfig.BASE_URL + '/news/postcomment/' + this.id, newComment)
            .then(function() {
                    var comment = CommentModel.new({
                        message: newComment,
                        fantasyClub: {
                            emblem: {
                                url: forClub.emblem.url
                            },
                            name: forClub.name
                        }
                    });
                    self.comments.limit = self.comments.push(comment);
                    self.commentsCount++;
                    return comment;
                },
                function(error) {
                    if (error.status === 400) {
                        self.deleted = true;
                    }
                });
    };

    NewsModel.prototype.post = function() {
        var self = this;
        return $http.post(bulimaConfig.BASE_URL + '/news/createnews', this.serialize())
            .then(function(response) {
                NewsModel.call(self, response.data);
                return self;
            });
    };

    return NewsModel;
});

'use strict';

angular.module('facebook', [])
    .provider('facebook', function(bulimaConfig) {

        var defered;
        var init = false;

        (function(d) {

            var js,
                id = 'facebook-jssdk',
                ref = d.getElementsByTagName('script')[0];

            if (d.getElementById(id)) {
                return;
            }

            js = d.createElement('script');
            js.id = id;
            js.async = true;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            ref.parentNode.insertBefore(js, ref);

        }(document));

        window.fbAsyncInit = function() {
            FB.init({
                appId: bulimaConfig.FB_APP_ID,
                xfbml: true,
                version: 'v2.4'
            });
            init = true;
            if (defered) {
                defered.resolve(FB);
            }
        };

        this.$get = ['$q', function($q) {
            if (init) {
                return $q.when(window.FB);
            } else {
                defered = $q.defer();
                return defered.promise;
            }
        }];
    });

require('./fbPage');

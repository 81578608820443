'use strict';

angular.module('dfs.common').directive('dfsBgChange', function ($rootScope, DfsBgImageConstant, $state) {
    return {
        name: 'dfsBgChange',
        scope: {},
        restrict: 'A',
        link: function($scope, element) {
            $rootScope.$on('$stateChangeSuccess', function() {
                var parts = $state.$current.name.split('.');
                var currentState = parts[0];
                if (parts.length > 1) {
                    currentState += '.' + parts[1];
                }
                if (DfsBgImageConstant[currentState]) {
                    element.css('background-image', 'url(' + DfsBgImageConstant[currentState] + ')');
                } else {
                    element.css('background-image', '');
                }
            });
        }
    };
});

require('./DfsBgImageConstant');

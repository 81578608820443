'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsFilterSelect', function () {
    return {
        name: 'dfsFilterSelect',
        templateUrl: 'dfsFilterSelect.html',
        replace: true,
        scope: {
            'filterList': '=',
            'filterPerRow': '@',
            'filterLayout': '@',
            'filterSelected': '&',
            'filterTranslate': '@',
            'filterNotEmpty': '=',
            'filterSingleSelect': '='
        },
        link: function (scope) {

              sortListPerRow();

            function sortListPerRow(){
                var filterList = [];
                var group = [];

                scope.filterList.forEach(function (item, index) {
                    if ((index + 1) % parseInt(scope.filterPerRow) !== 0) {
                        group.push(item);
                        if (index === scope.filterList.length - 1) {
                            filterList.push(group);                        
                            group = [];
                        }
                    } else {
                        group.push(item);
                        filterList.push(group);                        
                        group = [];
                    }
                });

                scope.sortFilterList = filterList;

            }
            function getSelectedCount() {
                var selectedCount = 0;
                scope.filterList.forEach(function (item) {
                    if (item.selected) {
                        selectedCount++;
                    }
                });
                return selectedCount;
            }

            scope.select = function (item) {
                if (scope.filterSingleSelect) {
                    if (!item.selected) {
                        for (var i = 0; i < scope.filterList.length; i++) {
                            scope.filterList[i].selected = false;
                        }
                        item.selected = true;
                        scope.filterSelected({ selected: item });
                    }
                } else {
                    if (item.selected && scope.filterNotEmpty && getSelectedCount() === 1) {
                        return;
                    }
                    if (item.selected) {
                        item.selected = false;
                    } else {
                        item.selected = true;
                    }
                    scope.filterSelected({ selected: item });
                }
            };
        }
    };
});
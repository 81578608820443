'use strict';

/* @ngInject */
angular.module('bm.squad.box.reserve').factory('SquadBoxReserveService',function ($filter, ReserveDispatcher) {

    var reservePlayers = [];

    /* Get all reserve players
    * id - if defined, get by id
    * pop - if true, pops the element */
    function get(id, pop) {
        if (id === undefined) {
            return reservePlayers;
        } else {
            var found = $filter('getById')(reservePlayers, id);
            if (pop) {
                del(id);
                ReserveDispatcher.replaceReserve(reservePlayers);
            }
            return found;
        }
    }

    /* Delete all reserve players
     * id - if defined, deletes by id */
    function del(id) {
        if (id === undefined) {
            reservePlayers = [];
        } else {
            var index = $filter('getIndexById')(reservePlayers, id);
            reservePlayers.splice(index, 1);
        }
        ReserveDispatcher.replaceReserve(reservePlayers);
    }

    /* Add a player to reserve players */
    function put(player) {
        if (!reservePlayers.length) {
            reservePlayers.push(player);
        } else {
            var newReserve = [];
            var added = false;
            for (var i = 0; i < reservePlayers.length; i++) {
                if (added) {
                    newReserve.push(reservePlayers[i]);
                    continue;
                }
                var tempPlayer = reservePlayers[i];
                if (tempPlayer.position.id !== player.position.id) {
                    if (player.position.id > tempPlayer.position.id) {
                        newReserve.push(player);
                        added = true;
                    }
                    newReserve.push(tempPlayer);
                } else {
                    if (player.totalPoints >= tempPlayer.totalPoints) {
                        newReserve.push(player);
                        added = true;
                    }
                    newReserve.push(tempPlayer);
                }
            }
            if (!added) {
                newReserve.push(player);
            }
            reservePlayers = newReserve;
        }
        ReserveDispatcher.replaceReserve(reservePlayers);
    }

    return {
        get: get,
        del: del,
        put: put
    };
});

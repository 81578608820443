'use strict';


angular.module('bm.news.content', []).directive('bmNewsContent', function() {
    return {
        restrict: 'EA',
        templateUrl: function() {
            return 'news-content-en.html';
        },
        controller: 'NewsContentCtrl',
        controllerAs: 'Content',
        bindToController: true,
        scope: {
            news: '='
        }
    };
});

require('./NewsContentCtrl');

'use strict';

angular.module('bm.squad.statistics.tabs.goals', []).directive('bmSquadStatisticsTabsGoals', function () {
    return {
        name: 'bmSquadStatisticsTabsGoals',
        templateUrl: 'bmSquadStatisticsGoalsTabs.html',
        controller: 'bmSquadStatisticsGoalsTabsCtrl',
        replace: true,
        scope: {
            getPremium: '&',
            showGraphClick : '&',
            datacolumnsGoals : '=datacolumns',
            datapointsGoals : '=datapoints',
            showData : '&',
            onrendered :'&onrendered',
            isPremium:'=',
            chartInformation:'=chartInformation'
        }
    };
});

require('./bmSquadStatisticsGoalsTabsCtrl');


'use strict';

angular.module('bm.stats-item').directive('bmStatsItemPoints', function ($filter) {
	return {
	    name: 'bmStatsItemPoints',
	    templateUrl: 'stats-item-points-component.html',
		replace: true,
		scope: {
		    'points': '=',
            'pointsFilter': '='
		},
		link: function (scope) {
		    var yesTest = ['yes', 'ja'];
		    var noTest = ['no', 'nein'];

		    scope.pointsClass = '';

		    if (isNaN(parseInt(scope.points))) {
		        if (yesTest.indexOf(scope.points.toLowerCase()) >= 0) {
		            scope.pointsClass = 'item-points-green';
		        }
		        if (noTest.indexOf(scope.points.toLowerCase()) >= 0) {
		            scope.pointsClass = 'item-points-red';
		        }
		    } else {
		        if (parseInt(scope.points) >= 0) {
		            scope.pointsClass = 'item-points-green';
		        } else {
		            scope.pointsClass = 'item-points-red';
		        }
		    }

		    if (scope.pointsFilter) {
		        scope.pointsValue = $filter(scope.pointsFilter)(scope.points);
		    } else {
		        scope.pointsValue = scope.points;
		    }
		}
	};
});

'use strict';

/* @ngInject */
angular.module('dfs.common').directive('stopEvent', function () {
    return {
        name: 'stopEvent',
        restrict: 'A',
        link: function (scope, element, attr) {
            element.on(attr.stopEvent, function (e) {
                e.stopPropagation();
            });
        }
    };
});
'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsChallengeBox', function ($filter, $interval) {
    return {
        name: 'dfsChallengeBox',
        templateUrl: 'dfsChallengeBox.html',
        replace: true,
        scope: {
            challengeType: '@',
            challengeTitle: '@',
            challengeSubtitle: '@',
            challengeDeadline: '@',
            challengePoints: '@',
            challengeCoins: '@',
            challengeImg: '@',
            isJoined: '@',
            challengeMatches: '=',
            challengeCost: '='
        },
        link: function (scope, element) {
            var classMap = {
                'top3': 'dfsChallengeBox-type-top3',
                'fiftyFifty': 'dfsChallengeBox-type-5050',
                'winnerTakesAll': 'dfsChallengeBox-type-wta'
            };

            var flagMap = {
                'ALB': 'assets/images/dfs/hexagon-flags/ALB.png',
                'AUT': 'assets/images/dfs/hexagon-flags/AUT.png',
                'BEL': 'assets/images/dfs/hexagon-flags/BEL.png',
                'CHE': 'assets/images/dfs/hexagon-flags/CHE.png',
                'CRO': 'assets/images/dfs/hexagon-flags/CRO.png',
                'CZE': 'assets/images/dfs/hexagon-flags/CZE.png',
                'ENG': 'assets/images/dfs/hexagon-flags/ENG.png',
                'ESP': 'assets/images/dfs/hexagon-flags/ESP.png',
                'FRA': 'assets/images/dfs/hexagon-flags/FRA.png',
                'GER': 'assets/images/dfs/hexagon-flags/GER.png',
                'HUN': 'assets/images/dfs/hexagon-flags/HUN.png',
                'IRL': 'assets/images/dfs/hexagon-flags/IRL.png',
                'ISL': 'assets/images/dfs/hexagon-flags/ISL.png',
                'ITA': 'assets/images/dfs/hexagon-flags/ITA.png',
                'NIR': 'assets/images/dfs/hexagon-flags/NIR.png',
                'POL': 'assets/images/dfs/hexagon-flags/POL.png',
                'POR': 'assets/images/dfs/hexagon-flags/POR.png',
                'ROU': 'assets/images/dfs/hexagon-flags/ROU.png',
                'RUS': 'assets/images/dfs/hexagon-flags/RUS.png',
                'SVK': 'assets/images/dfs/hexagon-flags/SVK.png',
                'SWE': 'assets/images/dfs/hexagon-flags/SWE.png',
                'TUR': 'assets/images/dfs/hexagon-flags/TUR.png',
                'UKR': 'assets/images/dfs/hexagon-flags/UKR.png',
                'WAL': 'assets/images/dfs/hexagon-flags/WAL.png'
            };

            scope.challengeClass = scope.challengeType && classMap[scope.challengeType] ? classMap[scope.challengeType] : '';

            if (scope.challengeMatches && scope.challengeMatches.length === 1) {
                scope.homeClubLogo = flagMap[scope.challengeMatches[0].homeClubShortName];
                scope.guestClubLogo = flagMap[scope.challengeMatches[0].guestClubShortName];
            }

            var countdown = $interval(function () {
                updateScope();
            }, 1000);

            element.on('$destroy', function () {
                $interval.cancel(countdown);
            });

            updateScope();

            function updateScope() {
                scope.remainingTime = scope.challengeDeadline - new Date().getTime() > 0 ? scope.challengeDeadline - new Date().getTime() : 0;
                if (scope.remainingTime <= 0) {
                    scope.expiration = '---';
                } else {
                    scope.expiration = $filter('timeRelative')(scope.remainingTime, true);
                }
                scope.disabled = scope.remainingTime <= 0;
            }
        }
    };
});
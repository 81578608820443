'use strict';

angular.module('bm.teamrank', []).directive('bmTeamrank', function () {
	return {
		name: 'bmTeamrank',
		templateUrl: 'teamrank-component.html',
		replace: true,
		scope: {
            'rank': '=teamrankRank',
		    'logo': '=teamrankLogo',
            'active': '=teamrankActive'
		}
	};
});

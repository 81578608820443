'use strict';
angular.module('dfs.shop-promo').controller('dfsShopPromoCtrl', dfsShopPromoCtrl);

/* @ngInject */
function dfsShopPromoCtrl($scope, $state, DfsShopPromoService) {
    var show = parseInt($scope.show) || 1;
    var selected;
    var num;
    var stateName = $state.current.name;

    $scope.promos = [];

    DfsShopPromoService.getBanners(stateName).then(function (banners) {
        for (var i = 0; i < show; i++) {
            if (banners.state.length > 0) {
                num = Math.floor(Math.random() * banners.state.length);
                selected = banners.state.splice(num, 1);
                $scope.promos.push(selected[0]);
            } else if (banners.all.length > 0) {
                num = Math.floor(Math.random() * banners.all.length);
                selected = banners.all.splice(num, 1);
                $scope.promos.push(selected[0]);
            }
        }
    });
}

(function(angular) {
    'use strict';

    angular.module('bm.fixedHeader').directive('bmFreeze', bmFreeze);

    /* @ngInject */
    function bmFreeze($window) {

        return {
            name: 'bmFreeze',
            restrict: 'A',
            link: function(scope, element, attrs) {

                var fixAt = parseInt(attrs.fixAt);
                var scrollWrapperName = attrs.scrollWrapper;
                var fixClass = attrs.fixClass;
                var wrapper = angular.element(scrollWrapperName);
                var w = angular.element($window);
                var lock = false;
                var hasClass = false;

                function scrollFn() {
                    if (lock) {
                        return;
                    }
                    lock = true;
                    var docViewTop = wrapper.scrollTop();
                    var docViewBottom = docViewTop + wrapper.height();
                    w.triggerHandler('click');

                    if ((fixAt <= docViewBottom) && (fixAt >= docViewTop)) {
                        element.removeClass(fixClass);
                        hasClass = false;
                    } else {
                        if (!hasClass) {
                            element.addClass(fixClass);
                            hasClass = true;
                        }
                    }
                    lock = false;
                }
                wrapper.on('scroll', scrollFn);

                element.on('$destroy', function() {
                    wrapper.off('scroll', scrollFn);
                });

            }
        };
    }
})(angular);

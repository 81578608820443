(function(angular){
    'use strict';

    /* @ngInject */
    function dfsTutorialsModalCtrl($scope, dfsTutorialsConstant, mngTutorialsConstant, params) {
        $scope.currentPageIndex = 0;
        $scope.isLastPage = false;
        $scope.locale = params.locale;

        if (params.mode === 'dfs') {
            $scope.tutorials = dfsTutorialsConstant;
        } else {
            $scope.tutorials = mngTutorialsConstant;
        }
        $scope.currentPage = $scope.tutorials[0];

        $scope.gotoPage = function (page) {
            if (page !== undefined) {
                $scope.currentPageIndex = page;
            } else {
                $scope.currentPageIndex++;
            }
            if ($scope.currentPageIndex === $scope.tutorials.length) {
                $scope.$close();
            } else {
                $scope.currentPage = $scope.tutorials[$scope.currentPageIndex];
            }
        };
    }

    angular.module('dfs.tutorials').controller('dfsTutorialsModalCtrl', dfsTutorialsModalCtrl);
})(angular);

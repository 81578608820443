'use strict';

/* @ngInject */
angular.module('bm.localization').directive('bmLanguageContent', function(LocalizationService) {
    return {
        restrict: 'A',
        priority: 1000,
        scope: false,
        link: function(scope, element, attrs) {
            var unwatchFn = angular.noop;

            if (!checkAndDestroy(LocalizationService.getLanguageKey())) {

                unwatchFn = scope.$watch(function() {
                    return LocalizationService.getLanguageKey();
                }, function(key) {
                    if (key) {
                        checkAndDestroy(key);
                    }
                });

            }

            function checkAndDestroy(key) {
                var allowedLang = attrs.bmLanguageContent;
                if (key !== allowedLang) {
                    element.remove();
                    return true;
                }
                return false;
            }

            scope.$on('$destroy', function() {
                unwatchFn();
                unwatchFn = null;
            });
        }
    };
});

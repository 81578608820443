'use strict';

require('./bm.squad.box.reserve/bm.squad.box.reserve');

/* @ngInject */
angular.module('bm.squad.box', [
    'bm.squad.box.reserve'
]).directive('bmSquadBox', function() {
    return {
        templateUrl: 'squad-box-component.html',
        bindToController: true
    };
});

'use strict';

/* @ngInject */
angular.module('bm.squad.slot').controller('UnlockSlotCtrl', function ($scope, params, PremiumItemsService, TokensService, $modalInstance, ClubService, $state, ReserveDispatcher, ModalService) {

    $scope.params = params;

    $scope.unlockSlot = function() {
        PremiumItemsService.activateBenchSlot().then(function() {
            $modalInstance.dismiss('cancel');
            ReserveDispatcher.unlockBench(1);
        });
    };

    $scope.payForBenchSlot = function() {
        var tokens = TokensService.amount;
        //PremiumItemsService.getBenchCost().then(function(result) {
        var itemCost = PremiumItemsService.getItem('benchPlace').price;
        if (tokens >= itemCost) {
            PremiumItemsService.activateBenchSlot().then(function() {
                $modalInstance.dismiss('cancel');
                ClubService.myClub.amountSubstituteBenchSlots += 1;
                ReserveDispatcher.unlockBench(1);
                TokensService.setAmount((tokens - itemCost));
            });
        } else {
            $modalInstance.dismiss('cancel');
            var param = {
                header: 'LINE_UP_UNLOCK_BENCH_MODAL.BENCH_SLOT',
                body: 'TRANSFER_SELL_PLAYER.ERROR_NOT_ENOUGH_PREMIUM_CURRENCY',
                btn1: 'GENERAL_TXT.BTN_CANCEL',
                btn: 'STATISTICS.BTN_BUY_PREMIUM_MODAL'
            };
            ModalService.openModalPopUp('go-to-shop-modal.html', '', '', param).then(function() {
                $state.go('app.shop.coins');
            });
        }
        //});
    };
});

'use strict';

angular.module('bm.common', []).filter('getById', function() {
    return function(input, id) {
        var len = input.length;
        for (var i = 0; i < len; i++) {
            if (+input[i].id === +id) {
                return input[i];
            }
        }
        return null;
    };
}).filter('getIndexById', function() {
    return function (input, id) {
        var len = input.length;
        for (var i = 0; i < len; i++) {
            if (+input[i].id === +id) {
                return i;
            }
        }
        return null;
    };
}).filter('convertToDecimal', function() {
    return function (input, param) {
        if(input && input !== undefined) {
            if(input % 1 !== 0) {
                return input.toFixed(param);
            }   
            return input;           
        }                
        return 0;
    };
}).filter('makePositive', function() {
    return function(num) { return Math.abs(num); }
});

require('./BaseClass');
'use strict';

/* @ngInject */
angular.module('bm.tutorials').constant('WEB_TUTORIALS_IMAGES', {
    'de-sport1': {
        HOME: [{
            id: 'HOME_1',
            url: 'assets/images/web_tutorials/de/sport1/HOME_1.jpg'
        }, {
            id: 'HOME_2',
            url: 'assets/images/web_tutorials/de/sport1/HOME_2.jpg'
        }, {
            id: 'HOME_3',
            url: 'assets/images/web_tutorials/de/sport1/HOME_3.jpg'
        }],
        LINE_UP: [{
            id: 'LINE_UP_1',
            url: 'assets/images/web_tutorials/de/sport1/LINE_UP_1.jpg'
        }, {
            id: 'LINE_UP_2',
            url: 'assets/images/web_tutorials/de/sport1/LINE_UP_2.jpg'
        }, {
            id: 'LINE_UP_3',
            url: 'assets/images/web_tutorials/de/sport1/LINE_UP_3.jpg'
        }, {
            id: 'LINE_UP_4',
            url: 'assets/images/web_tutorials/de/sport1/LINE_UP_4.jpg'
        }],
        TRANSFER_BUY: [{
            id: 'TRANSFER_BUY_1',
            url: 'assets/images/web_tutorials/de/sport1/TRANSFER_BUY_1.jpg'
        }, {
            id: 'TRANSFER_BUY_2',
            url: 'assets/images/web_tutorials/de/sport1/TRANSFER_BUY_2.jpg'
        }],
        TRANSFER_SELL: [{
            id: 'TRANSFER_SELL_1',
            url: 'assets/images/web_tutorials/de/sport1/TRANSFER_SELL_1.jpg'
        }, {
            id: 'TRANSFER_SELL_2',
            url: 'assets/images/web_tutorials/de/sport1/TRANSFER_SELL_2.jpg'
        }],
        CHALLENGE_INFO: [{
            id: 'CHALLENGE_INFO_1',
            url: 'assets/images/web_tutorials/de/sport1/CHALLENGE_INFO_1.jpg'
        }],
        CHALLENGE_LINE_UP: [{
            id: 'CHALLENGE_LINE_UP_1',
            url: 'assets/images/web_tutorials/de/sport1/CHALLENGE_LINE_UP_1.jpg'
        }]
    },
    'de-int': {
        HOME: [{
            id: 'LINE_UP_1',
            url: 'assets/images/web_tutorials/de/int/LINE_UP.jpg'
        }, {
            id: 'LINE_UP_2',
            url: 'assets/images/web_tutorials/de/int/PLAYER_CARD.jpg'
        }, {
            id: 'TRANSFER',
            url: 'assets/images/web_tutorials/de/int/TRANSFER.jpg'
        }, {
            id: 'TEAM_CALCULATIONS',
            url: 'assets/images/web_tutorials/de/int/TEAM_CALCULATIONS.jpg'
        }, {
            id: 'LEAGUE_TABLE',
            url: 'assets/images/web_tutorials/de/int/LEAGUE.jpg'
        }]
    },
    'en': {
        HOME: [{
            id: 'HOME_1',
            url: 'assets/images/web_tutorials/en/HOME_1.jpg'
        }, {
            id: 'HOME_2',
            url: 'assets/images/web_tutorials/en/HOME_2.jpg'
        }, {
            id: 'HOME_3',
            url: 'assets/images/web_tutorials/en/HOME_3.jpg'
        }],
        LINE_UP: [{
            id: 'LINE_UP_1',
            url: 'assets/images/web_tutorials/en/LINE_UP_1.jpg'
        }, {
            id: 'LINE_UP_2',
            url: 'assets/images/web_tutorials/en/LINE_UP_2.jpg'
        }, {
            id: 'LINE_UP_3',
            url: 'assets/images/web_tutorials/en/LINE_UP_3.jpg'
        }, {
            id: 'LINE_UP_4',
            url: 'assets/images/web_tutorials/en/LINE_UP_4.jpg'
        }],
        TRANSFER_BUY: [{
            id: 'TRANSFER_BUY_1',
            url: 'assets/images/web_tutorials/en/TRANSFER_BUY_1.jpg'
        }, {
            id: 'TRANSFER_BUY_2',
            url: 'assets/images/web_tutorials/en/TRANSFER_BUY_2.jpg'
        }],
        TRANSFER_SELL: [{
            id: 'TRANSFER_SELL_1',
            url: 'assets/images/web_tutorials/en/TRANSFER_SELL_1.jpg'
        }],
        CHALLENGE_INFO: [{
            id: 'CHALLENGE_INFO_1',
            url: 'assets/images/web_tutorials/en/CHALLENGE_INFO_1.jpg'
        }],
        CHALLENGE_LINE_UP: [{
            id: 'CHALLENGE_LINE_UP_1',
            url: 'assets/images/web_tutorials/en/CHALLENGE_LINE_UP_1.jpg'
        }]
    }
});

'use strict';

/* @ngInject */
angular.module('bm.squad.fixtures', []).directive('bmSquadFixtures', function() {
    return {
        templateUrl: 'squad-fixtures-component.html',
        controller: 'SquadFixturesCtrl',
        controllerAs: 'F'
    };
});

require('./SquadFixturesCtrl');

'use strict';

/* @ngInject */
angular.module('bm.localization', ['bm.common']);

require('./LocalizationServiceProvider');
require('./bm.language-content');
require('./bm.template-modifier');

angular.module('bm.localization')
    .config(function(
        bulimaConfig,
        MANAGER_INFO,
        LocalizationServiceProvider,
        $translateProvider) {

        var Localization = LocalizationServiceProvider;

        if (!bulimaConfig.DEBUG) {
            var translationFile = '/assets/translations/en.json';

            $translateProvider.useStaticFilesLoader({
                prefix: '/assets/translations/',
                suffix: translationFile.slice(23)
            });
        } else {
            $translateProvider.useMissingTranslationHandlerLog();
            $translateProvider.useLoader('TranslationFilesLoaderFactory', {
                files: [{
                    locale: 'de',
                    prefix: '',
                    suffix: (bulimaConfig.INTERNATIONAL ? '_001' : '_DE') + '.json?status=translated&key=' + bulimaConfig.LOCALIZATION_KEY
                }, {
                    locale: 'pl',
                    prefix: '',
                    suffix: '_PL.json?status=translated&key=' + bulimaConfig.LOCALIZATION_KEY
                }, {
                    locale: 'en',
                    prefix: '',
                    suffix: '_US.json?status=translated&key=' + bulimaConfig.LOCALIZATION_KEY
                }]
            });
        }

        var languages = Localization.languages;
        var languageKey;
        if (MANAGER_INFO.localization) {
            languageKey = MANAGER_INFO.localization.abbreviation;
        } else {
            languageKey = navigator.language || navigator.browserLanguage;
            if (bulimaConfig.INTERNATIONAL) {
                if (languageKey.indexOf(languages.DE) !== -1) {
                    languageKey = languages.DE;
                } else if (languageKey.indexOf(languages.PL) !== -1) {
                    languageKey = languages.PL;
                } else {
                    languageKey = languages.EN;
                }
            } else {
                languageKey = languages.DE;
            }
        }

        // var languageKey = languages.DE;
        Localization.setLanguageKey(languageKey);

        $translateProvider.preferredLanguage(languageKey);
        $translateProvider.fallbackLanguage(languages.EN);

        $translateProvider.useMessageFormatInterpolation();
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

    });

'use strict';

require('./LocalizationServiceProvider');

/* @ngInject */
angular.module('bm.localization').config(function($provide, LocalizationServiceProvider) {

    $provide.decorator('$templateCache', decorateTemplateCache);

    function decorateTemplateCache($delegate) {
        var originalGet = $delegate.get;

        $delegate.get = function(url) {
            var template = originalGet.call($delegate, url);
            template = stripTemplate(template);
            return template;
        };

        function stripTemplate(template) {
            var appLanguage = LocalizationServiceProvider.getLanguageKey();
            var tempTemplate = angular.element('<div>').append(template);
            tempTemplate.find('[data-lang-dom-keep]').each(function() {
                var element = angular.element(this);
                var data = element.data();
                var allowedLanguage = data.langDomKeep;

                if (allowedLanguage !== appLanguage) {
                    element.remove();
                }
            });

            return tempTemplate.html();
        }

        return $delegate;
    }

});

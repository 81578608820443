(function(angular) {
    'use strict';

    /* @ngInject */
    angular.module('facebook').directive('fbPage', function(facebook, bulimaConfig) {
        return {
            name: 'fbPage',
            template: function() {
                if (bulimaConfig.INTERNATIONAL) {
                    return '';
                } else {
                    return '<div class="fb-page" data-href="https://www.facebook.com/SPORT1BundesligaManager/?ref=aymt_homepage_panel" ' +
                        'data-width="300px" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"' +
                        'data-show-facepile="true" data-show-posts="true"> ' +
                        '<div class="fb-xfbml-parse-ignore">' +
                        '<blockquote cite="https://www.facebook.com/SPORT1BundesligaManager/?ref=aymt_homepage_panel"><a' +
                        'href="https://www.facebook.com/SPORT1BundesligaManager/?ref=aymt_homepage_panel">Bundesliga Manager von SPORT1</a></blockquote>' +
                        '</div>' +
                        '</div>';
                }
            },
            restrict: 'EA',
            link: function(scope, element) {
                if (!bulimaConfig.INTERNATIONAL) {
                    facebook.then(function(FB) {
                        FB.XFBML.parse(element.parent()[0]);
                    });
                }
            }
        };
    });

})(angular);

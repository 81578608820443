'use strict';
angular.module('bm.header').controller('HeaderCtrl', HeaderCtrl);

/* @ngInject */
function HeaderCtrl($scope, ClubService, UserService, PremiumItemsService, StatisticsService, MoneyService, TokensService, $state, $rootScope) {

    StatisticsService.getClubInLeagueRank();

    $scope.clubs = ClubService;
    //if (!$scope.clubs.officialLeagueClubs && !$scope.clubs.normalLeagueClubs && !$scope.clubs.noLeagueClubs) {
    //    ClubService.getFantasyClubs();
    //}
    ClubService.getFantasyClubs();

    $scope.stats = StatisticsService;
    $scope.premiumItems = PremiumItemsService;
    $scope.money = MoneyService;
    $scope.tokens = TokensService;
    $scope.userClubs = {
        seeClubs: false,
        nonHover: true
    };

    var deregisterStateStart = $rootScope.$on('$stateChangeStart', function() {
        $scope.ui.isMenuOpened = false;
    });

    $scope.ui = {
        premiumItemsOpened: false,
        userMenuOpened: false,
        isMenuOpened: false,
        premiumItemsOpenedFix: false,
        userMenuOpenedFix: false
    };

    $scope.logout = function() {
        UserService.logout();
    };
    $scope.toggleClubs = function($event) {
        $event.stopPropagation();
        $scope.userClubs.seeClubs = !$scope.userClubs.seeClubs;
    };

    /***
     *  Watch for drop down to be closed, and then hide club list
     * @type {*|function()}
     */
    var closeClubList = $scope.$watch('ui.userMenuOpened', function(newV, oldV) {
        if (newV !== oldV && !newV) {
            $scope.userClubs.seeClubs = false;
        }
    });

    $scope.changeClub = function(club) {
        $scope.userClubs.seeClubs = !$scope.userClubs.seeClubs;
        if (this.clubs.myClub.id !== club.id) {
            this.clubs.changeClub(club)
                .then(function() {
                    $state.go('app.home', null, {
                        'reload': true
                    });
                }, function() {
                    $state.go('registration.manager.step3.leagueType');
                });
        }
    };

    $scope.clubFocus = function() {
        $scope.userClubs.nonHover = false;
    };

    $scope.clubBlur = function() {
        $scope.userClubs.nonHover = true;
    };

    $scope.$on('$destroy', function() {
        deregisterStateStart();
        deregisterStateStart = null;
        closeClubList = null;
    });
}

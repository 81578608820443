'use strict';

Function.prototype.inherits = function(baseClass) {
    var _constructor = this;
    _constructor = baseClass.apply(_constructor);
};

/* @ngInject */
angular
    .module('bm.common')
    .factory('BaseClass', function() {

        function BaseClass() {
            var _constructor = this;
            // var _prototype = _constructor.prototype;

            _constructor.new = function(attributes) {
                var instance = new _constructor(attributes);
                return instance;
            };
        }

        BaseClass.Base = BaseClass;

        return BaseClass;
    });

'use strict';


/* @ngInject */
function bmSquadStatisticsTabsCtrl($scope, $state, $stateParams, $filter, DfsStatsSquadService, StatsPlayerService, UserService, bulimaConfig, $translate) {

    //set Default values
    $scope.activeTab = 'Points';
    $scope.languageKey = '';
   
    $scope.chartInformation = {
        activeTab: 'Points',
        imageBaseUrl: '/assets/images/icons/challenge/statistics-tabs/',
        graphPath: '/assets/images/icons/challenge/statistics-tabs/icodiagram.svg',
        premiumGraphPath: '/assets/images/icons/challenge/statistics-tabs/icodiagramblue.svg',
        graphPathCircle: '/assets/images/icons/challenge/statistics-tabs/icodiagram_circle.svg',
        backIcon: '/assets/images/icons/challenge/statistics-tabs/graph_back.svg',
        statistics: {},
        showGraph: false,
        datax: { 'id': 'x' },
        chartwidth: 1000,
        chartheight: 300,
        averageChartValue: 0,
        chartId: $scope.playerId,
        chartTitle: '',
        chartType: 'bar',
        label: ''
    };
    
    init();

    function init() {
        var myData = UserService.getManagerInfo();
        var time = new Date().getTime();
        if (myData) {
            $scope.isPremiumUser = (myData.user.premiumStatusActiveTimestamp !== null && myData.user.premiumStatusActiveTimestamp > time) ? true : false;
        } else {
            myData = UserService.getUserCurrentInfo();
            $scope.isPremiumUser = (myData.premiumStatusActiveTimestamp !== null && myData.premiumStatusActiveTimestamp > time) ? true : false;
        }

        if (myData.localization) {
            $scope.languageKey = myData.localization.abbreviation;
        } 

    }


    $scope.activateTab = function (tab) {
        $scope.activeTab = tab;
        $scope.chartInformation.showGraph = false;
    };
    
    $scope.getPlayerStatistics = function () {
        var leagueId = '';

        if ($stateParams.leagueId != undefined) {
            leagueId = $stateParams.leagueId;
        } else if($scope.leagueId != undefined){
            leagueId = $scope.leagueId;
        }       

        if (leagueId != undefined && leagueId != "") {
            DfsStatsSquadService.getPlayerStatistics($scope.playerId, leagueId).then(function (response) {
                $scope.chartInformation.statistics = response;
                $scope.chartInformation.statistics.marketValue = $scope.chartInformation.statistics.marketValue.toLocaleString().replace(/,/g, ".");
            });
        } else if ($stateParams.challengeId != undefined) {
            DfsStatsSquadService.getLeagueID($stateParams.challengeId).then(function (challenge) {
                DfsStatsSquadService.getPlayerStatistics($scope.playerId, challenge.data).then(function (response) {
                    $scope.chartInformation.statistics = response;
                    $scope.chartInformation.statistics.marketValue = $scope.chartInformation.statistics.marketValue.toLocaleString().replace(/,/g, ".");
                });
            });
        } else {
             StatsPlayerService.getPlayerLeague($scope.playerId).then(function (player) {
                DfsStatsSquadService.getPlayerStatistics($scope.playerId, player.data).then(function (response) {
                    $scope.chartInformation.statistics = response;
                    $scope.chartInformation.statistics.marketValue = $scope.chartInformation.statistics.marketValue.toLocaleString().replace(/,/g, ".");
                });
            });
        }

    };

    $scope.getPlayerStatistics();

    $scope.onrendered = function () {
        d3.selectAll('.c3-chart-texts text.c3-text')
            .attr('transform', 'translate(' + 0 + ',' + 30 + ')')
            .style('fill', '#56B06C');

        // for(var sp = 0; sp < d3.select('.c3-axis.c3-axis-x').selectAll('g.tick text')[0].length; sp++){
        //     d3.select('.c3-axis.c3-axis-x').selectAll('g.tick text')[0][sp].children[0].remove();
        // }

        if ($scope.chartInformation.chartType !== 'line') {
            for (var sp = 0; sp < d3.select('.c3-axis.c3-axis-x').selectAll('g.tick text')[0].length; sp++) {
                for (var t = 0; t < $scope.chartInformation.statistics.matchPlayerInfo.length; t++) {
                    if (parseInt(d3.select('.c3-axis.c3-axis-x').selectAll('g.tick text')[0][sp].children[0].innerHTML) == $scope.chartInformation.statistics.matchPlayerInfo[t].matchday) {
                        var label = "'" + $scope.chartInformation.statistics.matchPlayerInfo[t].timePlayer.toString();
                        d3.select('.c3-axis.c3-axis-x').selectAll('g.tick text')[0][sp].children[0].innerHTML = label;
                    }
                }

            }

            for (var y = 0; y < d3.select('.c3-axis.c3-axis-y').selectAll('g.tick')[0].length; y++) {
                d3.select('.c3-axis.c3-axis-y').selectAll('g.tick')[0][y].transform.baseVal.getItem(0).matrix.e = -5;
            }
        } else {
            for (var x = 0; x < d3.select('.c3-axis.c3-axis-x').selectAll('g.tick')[0].length; x++) {
                d3.select('.c3-axis.c3-axis-x').selectAll('g.tick')[0][x].transform.baseVal.getItem(0).matrix.f = 45;
            }

            for (var y = 0; y < d3.select('.c3-axis.c3-axis-y').selectAll('g.tick')[0].length; y++) {
                d3.select('.c3-axis.c3-axis-y').selectAll('g.tick')[0][y].transform.baseVal.getItem(0).matrix.e = -40;
            }
        }
     
        d3.select('.c3-axis.c3-axis-x').selectAll('g.tick text').each(function (d) {
            var p = d3.select(this.parentNode);

            var row = $scope.chartInformation.statistics.matchPlayerInfo[d];
            if (row !== undefined && $scope.chartInformation.chartType !== 'line')  {
                if (row.homeScore < 0){
                    row.homeScore = 0;
                }
                if (row.awayScore < 0){
                    row.awayScore = 0;
                }

                var scorelabel = row.homeScore + ' : ' + row.awayScore;
                var image = p.select('image');
                if (image[0][0] === null) {
                    var homeIcon = row.homeclublogo;
                    var gustIcon = row.awayclublogo;
                    p.append('text')
                        .text(scorelabel).attr({
                            'width': 14,
                            'height': 14,
                            'transform': 'translate(11, 25)',
                            'style': 'margin-bottom:2px'

                        });
                    p.append('image')
                        .attr({
                            'xlink:href': homeIcon,
                            'width': 14,
                            'height': 14,
                            'transform': 'translate(2, 30)',
                            'style': 'margin-bottom:2px'

                        });
                    p.append('image')
                        .attr({
                            'xlink:href': gustIcon,
                            'width': 14,
                            'height': 14,
                            'transform': 'translate(-14, 30)',
                            'style': 'margin-bottom:2px'

                        });
                }
            }
        });

        d3.select('.c3-legend-item').each(function (d) {
            var p = d3.select(this.parentNode);

            p.attr('transform', 'translate(0,310)');

        });
    };


    //------------Common Function -----------------------------//

    $scope.getPremium = function(){
        var url = bulimaConfig.APP_URL + '/#/challenges/shop/premium-account';
        // 'http://localhost:9000/#/challenges/shop/premium-account';        
        window.open(url);
    };

    $scope.showGraphClick = function (label, dataKey) {

        switch (dataKey) {
            case 'points':
                label = $translate.instant('PLAYER_STATISTICS_TOTAL_POINTS');
                break;
            case 'historyMarketValue':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_VALUE');
                break;
            case 'ratings':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_PERFORMANCE');
                break;
            case 'pgaPoints':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_SCORE_BONUS');
                break;
            case 'cardsPoints':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_CARD_MALUS');
                break;
            //PASSES
            case 'passes':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_PASSES');
                break;
            case 'passesRatio':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_PASSES_RATIO');
                break;
            case 'crosses':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_CROSSES');
                break;
                case 'crossesRatio':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_CROSSES_RATIO');
                break;
            //GOALS
                case 'goals':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_GOALS');
                break;
                case 'goalsAssist':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_GOALS_ASSISTS');
                break;
                case 'goalsAgainst':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_GOALS_AGAINST');
                break;
            //DUELS
                case 'duels':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_DUETS');
                break;
                case 'duelsWon':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_DUELS_WON');
                break;
                case 'duelsLost':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_DUELS_LOST');
                break;
                case 'foulsCommited':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_FOULS_COMMITTED');
                break;
                case 'foulsSuffered':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_FOULS_SUFFERED');
                break;
                case 'duelsRatio':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_DUELS_RATIO');
                break;
                //TOUCHES
                case 'touches':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_TOUCHES');
                break;
                //SHOTS
                case 'shots':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_SHOTS');
                break;
                case 'shotsOnTarget':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_ON_TARGET');
                break;
                case 'shotsOffTarget':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_OFF_TARGET');
                break;
                case 'shotsRatio':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_SHOTS_RATIO');
                break;
                case 'assits':
                label = $translate.instant('PLAYER_STATISTICS_MARKET_TOTAL_ASSISTS');
                break;
                default:
                break;
        }

        $scope.chartInformation.label = label;
            if (label === 'Market Value' || label === 'Marktwert') {
                $scope.chartInformation.chartType = 'line';
                $scope.datacolumns = [{ 'id': label, 'type': $scope.chartInformation.chartType, 'name': label }];
            }
            else {
                $scope.chartInformation.chartType = 'bar';
                $scope.datacolumns = [{ 'id': label, 'type': $scope.chartInformation.chartType, 'name': label }];

            }

            $scope.datapoints = [];
            $scope.chartInformation.chartTitle = label;

            if ($scope.chartInformation.statistics[dataKey] !== undefined) {
                var keys = undefined;                
                var values = Object.keys($scope.chartInformation.statistics[dataKey]).map(e => $scope.chartInformation.statistics[dataKey][e]);

                 if (label === 'Passes Ratio (%)' || label === 'Crosses Ratio (%)' || label === 'Flankenquote') { 
                    var pct = Object.keys($scope.chartInformation.statistics[dataKey]).map(e => $scope.chartInformation.statistics[dataKey][e].toFixed(0));
                    values = pct;
                }

                if (label === 'Card Malus (-)' || label === 'Strafpunkte') {
                    if (label == 'Strafpunkte') {            
                        $scope.chartInformation.chartTitle = 'Strafpunkte (-)';                        
                    }
                    var abs = Object.keys($scope.chartInformation.statistics[dataKey]).map(e => Math.abs($scope.chartInformation.statistics[dataKey][e]));
                    values = abs;
                }   
                
                if ($scope.chartInformation.chartType === 'line') {
                    //manually mapped because of date issue
                    var entries = Object.entries($scope.chartInformation.statistics[dataKey]);

                    var newData = [];
                    for (var e = 0; e < entries.length; e++) {
                        
                       var date = new Date(entries[e][0]);

                       var dd = date.getDate();
                       var mm = date.getMonth() + 1;
                       var yy = date.getFullYear();
                       
                       if (dd.toString().length <= 1) {
                            dd = '0' + dd;
                       }

                       if (mm.toString().length <= 1) {
                            mm = '0' + mm;
                       }

                       var modDate = dd + '/' + mm + '/' + yy;

                       var value = entries[e][1];

                       var row = {
                           date: modDate,
                           value: value
                       };

                       newData.push(row)
                    }
                    
                    function formatStringAsISO(s) {
                        var b = s.split(/\D/);
                        return b[2] + '-' + b[1] + '-' + b[0] + 'T' + b[3] + ':' + b[4];
                    }

                    newData.sort(function (a, b) {
                        return formatStringAsISO(a.date).localeCompare(formatStringAsISO(b.date));
                    });
                    
                    keys = [];
                    values = [];
                    for(var n = 0; n < newData.length; n++) {
                        keys.push(newData[n].date);    
                        values.push(newData[n].value);
                    }

                } else {
                    keys = Object.keys($scope.chartInformation.statistics[dataKey]);
                }

                $scope.chartInformation.averageChartValue = $scope.calculateAverage(values);
                $scope.getChartData(keys, values, label);

                if (keys.length <= 5) {
                    $scope.chartInformation.chartwidth = 300;
    
                }
                else if (keys.length <= 10) {
                    $scope.chartInformation.chartwidth = 500;
                }
                else if (keys.length > 10 && keys.length <= 21) {
                    $scope.chartInformation.chartwidth = 1000;                    
                } else {
                    $scope.chartInformation.chartwidth = 2000;
                }
            }

          

            $scope.chartInformation.showGraph = true;
    };


    $scope.getChartData = function (keys, values, label) {
        var count = keys.length;

        for (var i = 0; i < count; i++) {
            var info = $filter('filter')($scope.chartInformation.statistics.matchPlayerInfo, { matchday: keys[i] })[0];
            var datalabel = keys[i];
            // if (info !== undefined) {
            //     if (keys[i].length == 1) {
            //         datalabel = 'day  ' + keys[i] ;
            //     } else {
            //         datalabel = 'day ' + keys[i] ;
            //     }
                
            // }
            // if (label === 'Market Value') {
            //     datalabel = keys[i];
            // }
            datalabel = keys[i];
            var row = { 'x': datalabel, [label]: values[i] };            

            $scope.datapoints.push(row);
        }
        
    };


    $scope.showData = function () {
        $scope.chartInformation.showGraph = false;

    };
    $scope.calculateAverage = function (MyData) {
        var sum = 0;
        for (var i = 0; i < MyData.length; i++) {
            sum += MyData[i];
        }

        var avg = sum === 0 ? 0 :  sum / MyData.length;
        return $filter('convertToDecimal')(avg,1);
    };

    

}

angular.module('bm.squad.statistics').controller('bmSquadStatisticsTabsCtrl', bmSquadStatisticsTabsCtrl);

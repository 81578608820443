'use strict';

angular.module('dfs.shop-promo').constant('dfsShopPromoConstant', {
    en: [
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-adi-ball_300x250px_IP_v1_eng.jpg',
            id: 26
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-fitbit_300x250px_IP_v1_eng.jpg',
            id: 18
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-flag_300x250px_IP_v1_eng.jpg',
            id: 23
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-jersey_300x250px_IP_v1_eng.jpg',
            id: 24
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-wireless_300x250px_IP_v1_eng.jpg',
            id: 11
        }
    ],
    de: [
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-adi-ball_300x250px_IP_v1_ger.jpg',
            id: 26
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-fitbit_300x250px_IP_v1_ger.jpg',
            id: 18
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-flag_300x250px_IP_v1_ger.jpg',
            id: 23
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-jersey_300x250px_IP_v1_ger.jpg',
            id: 24
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-wireless_300x250px_IP_v1_ger.jpg',
            id: 11
        }
    ],
    pl: [
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-adi-ball_300x250px_IP_v1_pol.jpg',
            id: 26
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-fitbit_300x250px_IP_v1_pol.jpg',
            id: 18
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-flag_300x250px_IP_v1_pol.jpg',
            id: 23
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-jersey_300x250px_IP_v1_pol.jpg',
            id: 24
        },
        {
            imageUrl: '/assets/images/dfs/shop-promo/FS_Shop-promotions-wireless_300x250px_IP_v1_pol.jpg',
            id: 11
        }
    ]
});

'use strict';

angular.module('bm.squad.statistics.tabs.passes', []).directive('bmSquadStatisticsTabsPasses', function () {
    return {
        name: 'bmSquadStatisticsTabsPasses',
        templateUrl: 'bmSquadStatisticsPassesTabs.html',
        controller: 'bmSquadStatisticsPassesTabsCtrl',
        replace: true,
        scope: {
            getPremium: '&',
            showGraphClick : '&',
            datacolumnsPasses : '=datacolumns',
            datapointsPasses : '=datapoints',
            showData : '&',
            onrendered :'&onrendered',
            isPremium:'=',
            chartInformation:'=chartInformation'
        }
    };
});

require('./bmSquadStatisticsPassesTabsCtrl');


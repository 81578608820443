'use strict';

/* @ngInject */
angular.module('bm.squad.lineup.field')
    .directive('bmSquadLineupFieldPlayersLine', function ($compile, $templateCache, SwapFactory) {

    var lineStart = '<div class="players-line">';
    var lineEnd = '</div>';
    var playerParts = [
        '<div class="slotShadow"><div bm-squad-slot="players[',
        ']" allow-all-positions="',
        '" line="',
        '" on-drop="onDropHandler(player, slot)" slot="',
        '"></div></div>'
    ];

    return {
        restrict: 'AE',
        scope: {
            players: '=players',
            formation: '=formation',
            staticSlots: '=staticSlots',
            onDrop: '&onDrop'
        },
        link: function(scope, element) {
            scope.onDropHandler = function(player, slot) {
                scope.onDrop({
                    player: player,
                    slot: slot
                });
            };

            function createLineUp(formation, staticSlots) {
                var lineup = '<div>';
                var slot = formation.squadSize - 1;
                var positionNumber = 4;
                var i, j, k;
                var singleLineFormation = formation.length === 1;
                var formattedFormation = [];
                var maxPlayersPerLine = [6, 6, 6, 4];

                if (singleLineFormation) {
                    var totalPlayers = formation[0];
                    for (k = 0; k < maxPlayersPerLine.length; k++) {
                        if (totalPlayers > maxPlayersPerLine[k]) {
                            formattedFormation.push(maxPlayersPerLine[k]);
                            totalPlayers -= maxPlayersPerLine[k];
                        } else {
                            formattedFormation.push(totalPlayers);
                            totalPlayers = 0;
                        }
                    }
                } else {
                    formattedFormation = formation;
                }

                for (i = formattedFormation.length - 1; i >= 0; i--) {
                    var line = '';
                    lineup += lineStart;
                    for (j = 0; j < formattedFormation[i]; j++) {
                        if (staticSlots) {
                            line = '<div class="player-slot" bm-squad-player="players[' + slot + ']"></div>' + line;
                        } else {
                            line = playerParts[0] + slot + playerParts[1] + singleLineFormation + playerParts[2] + positionNumber + playerParts[3] + slot + playerParts[4] + line;
                        }
                        slot--;
                    }
                    positionNumber--;
                    lineup += line;
                    lineup += lineEnd;
                }
                lineup += '</div>';
                return lineup;
            }

            function refreshSquad(formation) {
                // var start = new Date();
                if (formation) {
                    element.empty();
                    var squadTemplate = createLineUp(formation, scope.staticSlots);
                    var squadElement = $compile(squadTemplate)(scope.$new());
                    element.append(squadElement);
                }
                // var end = new Date();
                // console.log('Execution time: ', end - start);
            }

            var unwatchFormation = scope.$watch('formation', refreshSquad);

            var lineCallbackId = SwapFactory.register(function(payload) {
                if (payload.actionType === 'line_slot_activate') {
                    scope.$apply(function() {
                        scope.lineEnabled = payload.line;
                    });
                }
            });

            scope.$on('$destroy', function() {
                unwatchFormation();
                SwapFactory.unregister(lineCallbackId);
            });
        }
    };
});
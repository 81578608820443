(function(angular) {
    'use strict';

    /* @ngInject */
    function SquadService(
        $http,
        bulimaConfig,
        PlayerFactory,
        PlayerTransformerService,
        Notifier,
        MoneyService,
        ModalService,
        $translate,
        SessionService,
        ClubService,
        SquadBoxReserveService,
        SquadLineupFieldService,
        SquadLineupBenchService,
        SquadLineupCaptainService,
        SquadLineupFormationService
    ) {
        var oldFormation;
        var formation;
        var formattedPlayers;
        var rendering;

        function init(newPlayers, newFormation, newCaptain, checkAuction) {
            setFormation(newFormation);
            SquadLineupFieldService.init(formation.squadSize);
            SquadBoxReserveService.del();
            SquadLineupBenchService.del();
            rendering = true;
            setCaptain(null);
            if (!newPlayers.length) {
                rendering = false;
                return;
            }
            var formattedCaptain = PlayerFactory.create(newCaptain);
            for (var i = 0, len = newPlayers.length; i < len; i++) {
                var player = newPlayers[i];
                player = PlayerFactory.create(player);
                if (!checkAuction && player.inAuction) {
                    continue;
                }
                if (player.slot) {
                    if (player.slot < formation.squadSize + 1) {
                        if ((formattedCaptain && formattedCaptain.id === player.id) || player.isCaptain()) {
                            player.setCaptain(true);
                            setCaptain(player);
                        }
                        SquadLineupFieldService.put(player, player.slot - 1);
                    } else {
                        SquadLineupBenchService.put(player, player.slot % 4);
                    }
                } else {
                    SquadBoxReserveService.put(player);
                }
            }
            formattedPlayers = preparePlayers(SquadLineupFieldService.get(), formation);
            rendering = false;
        }

        function setCaptain(player) {
            SquadLineupCaptainService.set(player);
            setSaved(false);
        }

        function getCaptain() {
            return SquadLineupCaptainService.get();
        }

        var lineName = {
            0: 'goalie',
            1: 'defense',
            2: 'midfield',
            3: 'forward'
        };

        function preparePlayers(players, formattedFormation) {
            var squadPlayers = {
                forward: [],
                midfield: [],
                defense: [],
                goalie: []
            };
            var line = 0;
            var position = lineName[line];
            for (var i = 0, j = 0; i < formattedFormation.squadSize;) {
                if (j === formattedFormation[line]) {
                    line++;
                    position = lineName[line];
                    j = 0;
                    continue;
                }
                squadPlayers[position][j] = players[i];
                j++;
                i++;
            }
            return squadPlayers;
        }

        function formatPlayers(formattedPlayers, formattedFormation) {
            //var start = new Date().getTime();
            var squadPlayers = formattedPlayers;
            var index = 0;
            var lineSquad;
            var j;
            for (var i = 0; i < formattedFormation.length; i++) {
                var difference = oldFormation[i] - formattedFormation[i];
                if (difference > 0) {
                    lineSquad = squadPlayers[lineName[i]];
                    index = 0;
                    var newLineSquad = new Array(formattedFormation[i]);
                    for (j = 0; j < oldFormation[i]; j++) {
                        if (lineSquad[j]) {
                            if (j >= formattedFormation[i] && newLineSquad[index]) {
                                var replaceIndex = 0;
                                var replacePlace = newLineSquad[replaceIndex];
                                for (var k = 0; k < formattedFormation[i]; k++) {
                                    if (newLineSquad[k].totalPoints < replacePlace.totalPoints) {
                                        replaceIndex = k;
                                        replacePlace = newLineSquad[k];
                                    }
                                }
                                if (replacePlace.totalPoints < lineSquad[j].totalPoints) {
                                    newLineSquad[replaceIndex] = lineSquad[j];
                                    movePlayers(replacePlace);
                                } else {
                                    movePlayers(lineSquad[j]);
                                }
                            } else {
                                newLineSquad[index] = lineSquad[j];
                                index = (index + 1) % formattedFormation[i];
                            }
                        }
                    }
                    squadPlayers[lineName[i]] = newLineSquad;
                }
            }
            //var end = new Date().getTime();
            //var time = end - start;
            //console.log('Execution Time: ', time);
            return squadPlayers;
        }

        function renderPlayers() {
            formattedPlayers = preparePlayers(SquadLineupFieldService.get(), oldFormation);
            formattedPlayers = formatPlayers(formattedPlayers, formation);
            setSaved(false);
        }

        function getPlayers() {

            var tempPlayers = [];
            var slot = 0;
            var linePlayers;
            var player;

            if (formattedPlayers) {
                for (var i = 0; i < formation.length; i++) {
                    linePlayers = formattedPlayers[lineName[i]];
                    for (var j = 0; j < formation[i]; j++) {
                        player = linePlayers[j];
                        if (player) {
                            player.slot = slot;
                        }
                        slot++;
                        tempPlayers.push(player);
                    }
                }
            }

            SquadLineupFieldService.set(tempPlayers);

            return {
                squadPlayers: SquadLineupFieldService.get(),
                reservePlayers: SquadBoxReserveService.get(),
                benchPlayers: SquadLineupBenchService.get(),
                formation: SquadLineupFormationService.get()
            };
        }

        function getAllPlayers() {
            var squadPlayers = SquadLineupFieldService.getAll(),
                reservePlayers = SquadBoxReserveService.get(),
                benchPlayers = SquadLineupBenchService.get(),
                allPlayers = [];

            allPlayers = allPlayers.concat(reservePlayers, benchPlayers, squadPlayers);

            return allPlayers;
        }

        function setFormation(newFormation) {
            oldFormation = SquadLineupFormationService.set(newFormation);
            formation = SquadLineupFormationService.get();
            return formation;
        }

        function getFormation() {
            return SquadLineupFormationService.get();
        }

        function getFormations() {
            return $http.post(bulimaConfig.BASE_URL + '/readteamservice/allFormations')
                .then(function(response) {
                    return response.data;
                });
        }

        function movePlayers(player, toSlot) {
            if (!player) {
                return;
            }
            var fromSlot = player.slot;
            var replacedPlayer = null;

            if (Number(toSlot) === toSlot) {
                player.slot = toSlot;
                if (toSlot > 11) {
                    if (player.isCaptain()) {
                        player.setCaptain(false);
                        SquadLineupCaptainService.set(null);
                    }
                    replacedPlayer = SquadLineupBenchService.put(player, toSlot % 4);
                } else {
                    replacedPlayer = SquadLineupFieldService.put(player, toSlot);
                    if (replacedPlayer && replacedPlayer.isCaptain() && (!fromSlot || fromSlot > 11)) {
                        replacedPlayer.setCaptain(false);
                        SquadLineupCaptainService.set(null);
                    }
                }
            } else {
                if (player.captain) {
                    player.setCaptain(false);
                    SquadLineupCaptainService.set(null);
                }
                player.slot = null;
                SquadBoxReserveService.put(player);
            }

            if (Number(fromSlot) === fromSlot) {
                if (fromSlot > 11) {
                    if (replacedPlayer) {
                        replacedPlayer.slot = fromSlot;
                        SquadLineupBenchService.put(replacedPlayer, fromSlot % 4);
                    } else {
                        SquadLineupBenchService.del(fromSlot % 4);
                    }
                } else {
                    if (replacedPlayer) {
                        if (toSlot > 11) {
                            replacedPlayer.slot = null;
                            SquadBoxReserveService.put(replacedPlayer);
                            SquadLineupFieldService.del(fromSlot);
                        } else {
                            replacedPlayer.slot = fromSlot;
                            SquadLineupFieldService.put(replacedPlayer, fromSlot);
                        }
                    } else {
                        SquadLineupFieldService.del(fromSlot);
                    }
                }
            } else {
                SquadBoxReserveService.del(player.id);
                if (replacedPlayer) {
                    replacedPlayer.slot = null;
                    SquadBoxReserveService.put(replacedPlayer);
                }
            }

            setSaved(false);
        }

        function saveFormation(storageId) {
            var captain = SquadLineupCaptainService.get();
            var formationData = {
                activeFormation: {
                    name: formation.name
                },
                captain: captain ? PlayerTransformerService.transformForRequest(captain) : null
            };

            var club = ClubService.getClubInfo();
            angular.extend(formationData, {
                id: club.id,
                managerName: club.managerName,
                emblem: {
                    id: club.emblem && club.emblem.id
                }
            });

            var i;
            var players = SquadLineupFieldService.get();
            var bench = SquadLineupBenchService.get();
            var player;
            var fantasyPlayers = [];
            var notSetPlayer = false,
                saveAnyway = true;
            for (i = 0; i < players.length; i++) {
                player = PlayerTransformerService.transformForRequest(players[i]);
                if (player) {
                    fantasyPlayers.push(player);
                } else {
                    notSetPlayer = true;
                }
            }

            var reservePlayers = SquadBoxReserveService.get();

            for (i = 0; i < reservePlayers.length; i++) {
                player = PlayerTransformerService.transformForRequest(reservePlayers[i]);
                if (player) {
                    fantasyPlayers.push(player);
                }
            }
            for (i = 0; i < bench.length; i++) {
                player = PlayerTransformerService.transformForRequest(bench[i]);
                if (player) {
                    player.playerSlot.id--;
                    fantasyPlayers.push(player);
                }
            }

            formationData.fantasyPlayer = fantasyPlayers;
            var notSetCaptain = !formationData.captain,
                negativeBudget = (MoneyService.amount < 0);

            if (notSetPlayer || notSetCaptain || negativeBudget) {
                var data = SessionService.getLineUpFlag(storageId);
                if (data) {
                    if (notSetPlayer && data.NOT_FULL) {
                        notSetPlayer = false;
                    }
                    if (notSetCaptain && data.NO_CAPTAIN) {
                        notSetCaptain = false;
                    }
                    if (negativeBudget && data.BUDGET_NEGATIVE) {
                        negativeBudget = false;
                    }
                } else {
                    saveAnyway = false;
                }
            }
            if (saveAnyway && !notSetPlayer && !notSetCaptain && !negativeBudget) {
                return saveLineup(formationData);
            } else {
                var msg = notSetPlayer ? 'NOT_FULL' : (notSetCaptain ? 'NO_CAPTAIN' : 'BUDGET_NEGATIVE');
                return openInfoModal(msg, storageId)
                    .then(function() {
                        return saveLineup(formationData);
                    });
            }
        }

        function saveLineup(formationData) {
            return $http.post(bulimaConfig.BASE_URL + '/writefantasyclubservice/updateUserFantasyClub', formationData).then(function() {
                setSaved(true);
                if (warningId) {
                    Notifier.close(warningId);
                    warningId = null;
                }
                Notifier.success('LINE_UP_SQUAD.SUCCESS_LINEUP_SAVED');
            });
        }

        var saved = true;
        var warningId;

        function setSaved(flag) {
            if (!rendering) {
                saved = flag;
            }
        }

        function isSaved(stateChange) {
            var oldSaved = saved;
            if (stateChange) {
                if (warningId) {
                    Notifier.close(warningId);
                    warningId = null;
                }
                if (!saved) {
                    warningId = Notifier.warning('CHALLENGE.SAVE_BEFORE_PAGE_CHANGE', 5000);
                    setSaved(true);
                }
            }
            return oldSaved;
        }

        /**
         * This method create pop up for help
         */
        function openInfoModal(errorType, storageId) {
            var params = {
                header: 'DFS_GLOBAL.WARNING',
                body: $translate.instant('LINE_UP_ERROR_FLAGS.' + errorType),
                checkbox: 'LINE_UP_ERROR_FLAGS.DEACTIVATE',
                btn: 'LINE_UP_ERROR_FLAGS.BTN_BACK',
                btnSave: 'LINE_UP_ERROR_FLAGS.BTN_SAVE_ANYWAY',
                flagName: errorType,
                storageId: storageId
            };
            return ModalService.openModalPopUp('squad-save-modal.html', 'SquadSaveModalCtrl', 'auction', params);
        }

        return {
            init: init,
            getPlayers: getPlayers,
            getAllPlayers: getAllPlayers,
            setFormation: setFormation,
            getFormation: getFormation,
            getFormations: getFormations,
            renderPlayers: renderPlayers,
            movePlayers: movePlayers,
            setCaptain: setCaptain,
            getCaptain: getCaptain,
            saveFormation: saveFormation,
            isSaved: isSaved,
            saveLineup: saveLineup
        };
    }

    angular.module('bm.squad').factory('SquadService', SquadService);

})(angular);

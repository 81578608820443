'use strict';

angular.module('bm.share-modal').service('ShareService', function($http, bulimaConfig, storage) {

    this.invite = invite;
    this.dontShowAgain = dontShowAgain;
    this.doShowShare = doShowShare;

    function invite(users) {
        var postData = [];
        for (var index in users) {
            postData.push(users[index].email);
        }
        return $http.post(bulimaConfig.BASE_URL + '/mailservice/sendReferralMails', postData);
    }

    function dontShowAgain() {
        storage.set('BM_DONT_SHARE', true);
    }

    function doShowShare() {
        return !(storage.get('BM_DONT_SHARE') || false);
    }
});

'use strict';

/* @ngInject */
angular.module('dfs.common').directive('dfsCheckbox', function () {
    return {
        name: 'dfsCheckbox',
        templateUrl: 'dfsCheckbox.html',
        replace: true,
        scope: {
            ngModel: '=',
            checkboxSize: '@'
        }
    };
});
'use strict';

/* @ngInject */
angular.module('bm.create.news', []).directive('bmCreateNews', function () {
    return {
        restrict: 'EA',
        templateUrl: 'create-news.html',
        controller: 'CreateNewsCtrl as Create'
    };
});

require('./CreateNewsCtrl');

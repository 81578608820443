'use strict';

angular.module('bm.team-calculation-player').directive('bmTeamCalculationPlayerPoints', function () {
	return {
	    name: 'bmTeamCalculationPlayerPoints',
	    templateUrl: 'team-calculation-player-points-component.html',
		replace: true,
		scope: {
		    'points': '='
		},
		link: function (scope) {
		    var yesTest = ['yes', 'ja'];
		    var noTest = ['no', 'nein'];

		    scope.pointsClass = '';

		    if (isNaN(parseInt(scope.points))) {
		        if (yesTest.indexOf(scope.points.toLowerCase()) >= 0) {
		            scope.pointsClass = 'player-points-green';
		        }
		        if (noTest.indexOf(scope.points.toLowerCase()) >= 0) {
		            scope.pointsClass = 'player-points-red';
		        }
		    } else {
		        if (parseInt(scope.points) >= 0) {
		            scope.pointsClass = 'player-points-green';
		        } else {
		            scope.pointsClass = 'player-points-red';
		        }
		    }
		}
	};
});

'use strict';


/* @ngInject */
function bmSquadStatisticsTouchesTabsCtrl() {



}

angular.module('bm.squad.statistics.tabs.touches').controller('bmSquadStatisticsTouchesTabsCtrl', bmSquadStatisticsTouchesTabsCtrl);

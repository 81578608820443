'use strict';

/* @ngInject */
angular.module('bm.squad.lineup.bench', []).directive('bmSquadLineupBench',function bmBench(SquadService) {
    return {
        name: 'bmSquadLineupBench',
        scope: {
            bench: '=bmSquadLineupBench',
            unlock: '=bmUnlock'
        },
        restrict: 'AE',
        templateUrl: 'squad-lineup-bench-component.html',
        replace: true,
        link: function(scope) {
            scope.onBenchDrop = function(player, slot) {
                var initBenchPosition = 11;
                if (slot <= initBenchPosition + scope.unlock) {
                    SquadService.movePlayers(player, slot);
                }
            };
        }
    };
});

require('./SquadLineupBenchService');
